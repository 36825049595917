import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'   //样式表
import enLang from 'element-ui/lib/locale/lang/zh-CN'// 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖
// register globally
import './auto-update'
import './persistPlugin'
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

import vueMiniPlayer from "vue-mini-player";
import "vue-mini-player/lib/vue-mini-player.css";
 Vue.use(vueMiniPlayer)

import '@/svgIcon/index.js'
import setStorage from '@/utils/Storge'/* 封装缓存 */
Vue.prototype.$Storage=setStorage  
/* import 'lib-flexible'//适配依赖 */
import '@/utils/lib-flexible'

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import '@/css/all.css'   //全局样式表
import md5 from "js-md5" //md5加密
Vue.prototype.$md5=md5
import UploadComponent from './components/UploadComponent'
Vue.component('UploadComponent',UploadComponent)
import popoverSelect from '@/components/popoverSelect/index.vue'//popoverSelect
Vue.component('popoverSelect',popoverSelect)

import vHtmlPanel from "@/components/htmlpanel/HtmlPanel.vue"
Vue.component("v-html-panel",vHtmlPanel)




import bigUpload from "@/components/bigUpload/index.vue"//大文件上传
Vue.component("bigUpload",bigUpload)

import mixin from './mixins/mixin.js'
Vue.prototype.$mixin = mixin;

Vue.use(Element, {
  size: 'medium', // set element-ui default size
  locale: enLang // 如果使用中文，无需设置，请删除
})
Vue.prototype.$Element=Element  
// import extendedSelect from "@/components/diyelement/myselect.vue";
// Vue.component("extendedSlect",extendedSelect)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
