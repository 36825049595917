<template>
  <div>
    <el-menu
      :default-active="navLeftActive"
      class="el-menu-demo"
     
      style="border: none;"
      mode="horizontal"
      menu-trigger="hover"
      background-color="transparent"
      active-text-color="#08308F"
      active-background-color="transparent"
      text-color="#000"
      :router="false"
      
      @select="handleSelect"
    >
      <el-menu-item index="/home">首页</el-menu-item>
      <el-submenu popper-class="back" index="/ProjectCenter">
        <template slot="title">项目中心</template>
        <el-menu-item index="/ProjectCenter/MyProject">我的项目</el-menu-item>
        <el-menu-item index="/ProjectCenter/MyMission">我的任务</el-menu-item>
        <el-menu-item index="/ProjectCenter/MySample" v-if="$Storage.getItem('adm_level') !== 4">我的样品</el-menu-item>
        <el-menu-item index="/ProjectCenter/MyData"  v-if="$Storage.getItem('adm_level') !== 4">我的数据</el-menu-item>
        <el-menu-item index="/ProjectCenter/MyCloudGroup"  v-if="$Storage.getItem('adm_level') !== 4"
          >我的云组</el-menu-item
        >
        <el-menu-item index="/ProjectCenter/MyCourse"  v-if="$Storage.getItem('adm_level') !== 4">我的课程</el-menu-item>
      </el-submenu>
      <el-submenu popper-class="back" index="/cloudServices">
        <template slot="title">科研云服务</template>
        <el-menu-item index="/cloudServices/cloudProcess" v-if="$Storage.getItem('adm_level') !== 4">云流程</el-menu-item>
        <el-menu-item index="/cloudServices/cloudTools">云工具</el-menu-item>
        <el-menu-item index="/cloudServices/cloudDraw">绘图工具</el-menu-item>
      </el-submenu>
      <el-menu-item index="/yuzhong"  v-if="$Storage.getItem('adm_email').indexOf('smartbreeding')!=-1||$Storage.getItem('adm_email').indexOf('136058626@qq.com')!=-1">育种工具</el-menu-item>
      <!-- <el-menu-item index="/database">数据库</el-menu-item> -->
      <el-submenu popper-class="back" index="/database" v-if="$Storage.getItem('adm_level') !== 4">
        <template slot="title" >数据库</template>
        <el-menu-item index="/database/cc">单基因组</el-menu-item>
        <el-menu-item index="/database/dd">泛基因组</el-menu-item>
        <el-menu-item index="/database/dd1">重测序</el-menu-item>
        <el-menu-item index="/database/dd2">种质资源(植物)</el-menu-item>
        <el-menu-item index="/database/dd3">种质资源(动物)</el-menu-item>
        <el-menu-item index="/database/dd4">多组学</el-menu-item>
      </el-submenu>
      <el-submenu popper-class="back" index="/Product"  v-if="$Storage.getItem('adm_level') !== 4||$Storage.getItem('is_vip') == 1">
        <template slot="title">极智云课程</template>
        <!--  <el-menu-item index="/Product/courses">产品课程</el-menu-item>
        <el-menu-item index="/Product/program">编程课程</el-menu-item>
        <el-menu-item index="/Product/cooperation">合作项目详解</el-menu-item> -->
        <!-- <el-menu-item index="/Product/training">培训班</el-menu-item> -->
        <el-menu-item index="/introduce/video" v-if="$Storage.getItem('adm_level') !== 4||$Storage.getItem('is_vip') == 1">视频</el-menu-item>
        <!-- <el-menu-item index="/Product/articleView">文献</el-menu-item> -->
      </el-submenu>
      <el-submenu popper-class="back" index="/introduce" >
        <template slot="title">极智云介绍</template>
        <el-menu-item index="/introduce/platform">平台介绍</el-menu-item>
        <!--  <el-menu-item index="/introduce/basic">基础功能</el-menu-item> -->
      
        <el-menu-item index="/introduce/method">使用方法</el-menu-item>
        <el-menu-item index="/introduce/FAQ">FAQ</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>
<script>
import { showDataBaseImg, addDbNum } from "@/api/welcome";
export default {
  name: "MenuTab",
  props: {
    top: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  components: {
    //引入模块
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key);
      var temporaryClose = [
        "/cloudServices/cloudProcess",
        // "/yuzhong",
       
      ];
      if (temporaryClose.includes(key)) {
        if (this.$Storage.getItem("adm_level") == 4) {
          this.$alert("正在开发中", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
          return;
        }
      }
      if (this.$Storage.getItem("adm_name")) {
        if (key == "/database/cc") {
          var data = {
            type: this.$Storage.getItem("adm_name") ? 2 : 1,
          };
          addDbNum(data).then((response) => {
            console.log(response);
            if (response.data.code == 1) {
              // this.$message.success(response.data.msg);
            } else {
              this.$message({
                showClose: false,
                message: response.data.msg,
                type: "error",
                offset: "100",
              });
            }
          });
          window.open("https://sgd.smartgenomics.net/#/home", "_blank");
          return;
        }
        if (key == "/database/dd") {
          var data = {
            type: this.$Storage.getItem("adm_name") ? 2 : 1,
          };
          addDbNum(data).then((response) => {
            console.log(response);
            if (response.data.code == 1) {
              // this.$message.success(response.data.msg);
            } else {
              this.$message({
                showClose: false,
                message: response.data.msg,
                type: "error",
                offset: "100",
              });
            }
          });
          window.open("https://pangd.smartgenomics.net/#/home", "_blank");
          return;
        }
        if (key == "/database/dd1") {
          var data = {
            type: this.$Storage.getItem("adm_name") ? 2 : 1,
          };
          addDbNum(data).then((response) => {
            console.log(response);
            if (response.data.code == 1) {
              // this.$message.success(response.data.msg);
            } else {
              this.$message({
                showClose: false,
                message: response.data.msg,
                type: "error",
                offset: "100",
              });
            }
          });
          window.open("https://rgd.smartgenomics.net/#/home", "_blank");
          return;
        }
        if (key == "/database/dd2") {
          var data = {
            type: this.$Storage.getItem("adm_name") ? 2 : 1,
          };
          addDbNum(data).then((response) => {
            console.log(response);
            if (response.data.code == 1) {
              // this.$message.success(response.data.msg);
            } else {
              this.$message({
                showClose: false,
                message: response.data.msg,
                type: "error",
                offset: "100",
              });
            }
          });
          window.open("https://germ.smartgenomics.net/#/", "_blank");
          return;
        }
        if (key == "/database/dd3") {
          var data = {
            type: this.$Storage.getItem("adm_name") ? 2 : 1,
          };
          addDbNum(data).then((response) => {
            console.log(response);
            if (response.data.code == 1) {
              // this.$message.success(response.data.msg);
            } else {
              this.$message({
                showClose: false,
                message: response.data.msg,
                type: "error",
                offset: "100",
              });
            }
          });
          window.open("https://grd.smartgenomics.net/#/", "_blank");
          return;
        }
        if (key == "/database/dd4") {
          var data = {
            type: this.$Storage.getItem("adm_name") ? 2 : 1,
          };
          addDbNum(data).then((response) => {
            console.log(response);
            if (response.data.code == 1) {
              // this.$message.success(response.data.msg);
            } else {
              this.$message({
                showClose: false,
                message: response.data.msg,
                type: "error",
                offset: "100",
              });
            }
          });
          window.open("https://omics.smartgenomics.net/#/home", "_blank");
          return;
        }
        this.$router.push({ path: key });
      } else {
        if (key == "/home") {
          this.$router.push({ path: "/welcome" });
          return;
        }
        this.$confirm("请先登录后再进行浏览", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({ path: "/login" });
            /*   */
          })
          .catch(() => {});
      }
      console.log(key, keyPath);
    },
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
    navLeftActive: function () {
      console.log(this.$route);
      const { meta, path } = this.$route;
      console.log(path, 111);
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path; //"/" + path.split("/")[1];
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  mounted: function () {
    
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    console.log("Home");
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style scoped>
.el-menu-demo {
  width: 816px;

  height: 80px;
  display: flex;

  justify-content: center;
}
.el-menu-demo >>>.el-menu--inline{
  background: #fff !important;
}
.el-menu-demo>>>.el-submenu__title{
  width:130px;
  text-align: center;
}
.el-menu-demo>>>.el-submenu{
  width:130px;
}
.el-menu-demo>>>.el-submenu .el-menu-item{
  padding: 0 30px !important;
  min-width: auto !important;
}

.el-menu--horizontal>>>.el-menu-item{
  font-size: 16px !important;
}
.el-menu-demo>>>.el-submenu__title{
  font-size: 16px !important;
}
</style>
<style>
.el-menu-item.is-active{
  color: #08308F !important;
  border: 0 !important;
}

.back{
  background-color: white !important;
  border-top: 1px solid #eee;
    margin-top: -25px;
}
.el-submenu{
  color: #08308F !important;
}
.el-submenu .is-active{
  color: #08308F !important;
}
.el-submenu .is-active>>>.el-submenu__title{
  color: #08308F !important;
}
.el-menu--popup{
  /* padding: 0px 27px !important; */
  min-width: auto !important;
  width: 130px !important;
  text-align: center;
}
.el-submenu.is-active .el-submenu__title  {
  border-bottom: 0 !important;
}
.el-submenu__title:focus, .el-submenu__title:hover{
    outline: 0 !important;
    color: #08308F  !important;
    background: none !important;
}
.el-menu-item:hover{
    outline: 0 !important;
    color: #08308F !important;
    background: none !important;
}

</style>

