<template>
  <div class="Main">
    <!-- 头部 11-->

    <!-- 轮播 -->
    <div class="bannerBox">
      <el-carousel
        class="bannerBoxTop"
        height="2.864583rem"
        @change="carouselChange"
        trigger="click"
        ref="carousel"
        @mouseenter.native="delHandleMouseEnter"
        @mouseleave.native="delHandleMouseEnter1"
        :autoplay="flag"
      >
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <a :href="item.path" target="_blank" v-if="item.path != '#'"
            ><img :src="item.img_name" class="bannerImage"
          /></a>
          <img :src="item.img_name" class="bannerImage" v-else />
        </el-carousel-item>
      </el-carousel>
    </div>
    <template v-for="(item, index) in contViewList">
      <div
        v-if="item.type == '左右布局'"
        class="content1"
        :id="index"
        :style="{ '--backgroundColor': '' }"
      >
        <div
          v-for="(itm, ind) in item.img"
          :key="ind"
          :style="{ float: 'left', width: '50%', 'margin-right': 165 / 192 + 'rem' }"
          v-show="item.location != '左侧对齐'"
        >
          <!-- <img :src="itm.name"  /> -->
          <template v-for="it in itm.fileList">
            <img
              :src="imgurl + it.name"
              :class="{
                imgleft: intersectionObserverList.indexOf(index) != -1,
                imgBoxcon: true,
              }"
            />
          </template>
        </div>
        <div class="jizhiyun" :style="{ float: 'left', width: 'fit-content' }">
          <div class="Title" :style="{ '--Color': item.title_color }">
            {{ item.title }}
          </div>
          <div class="jieshao">{{ item.jieshao }}</div>
        </div>
        <div
          v-for="(itm, ind) in item.img"
          :key="ind"
          :style="{ float: 'left', width: '50%', 'margin-left': 165 / 192 + 'rem' }"
          v-show="item.location == '左侧对齐'"
        >
          <template v-for="it in itm.fileList">
            <img
              :src="imgurl + it.name"
              :class="{
                imgright: intersectionObserverList.indexOf(index) != -1,
                imgBoxcon: true,
              }"
            />
          </template>
        </div>
      </div>
      <div
        v-if="item.type == '全布局'"
        class="content2"
        :id="index"
        :style="{
          'background-image': 'url(' + imgurl + item.background_url[0].name + ')',
        }"
      >
        <el-row
          :gutter="33"
          class="jbcol"
          :style="{ width: 1200 / 192 + 'rem', margin: 'auto' }"
        >
          <el-col
            :span="8"
            v-for="(itm, ind) in item.img"
            :key="ind"
            :style="{
              paddingLeft: ind == 0 || ind == 3 ? 0 : '16.5px',
              paddingRight: (ind + 1) % 3 ? '16.5px' : 0,
            }"
            :class="{
              imgdown: intersectionObserverList.indexOf(index) != -1,
              imgdown1: true,
            }"
          >
            <template v-for="it in itm.fileList">
              <div class="djht" @click="jump1(itm)">
                <div>
                  <div class="srcimg">
                    <img class="djhtimg" :src="imgurl + it.name" />
                  </div>

                  <div>{{ itm.imgTitle }}</div>
                </div>
              </div>
            </template>
          </el-col>
        </el-row>
      </div>
    </template>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import PingtaiJieshao from "@/components/introduce/platform.vue";
import { getGongsiList } from "@/api/list";
import { getPieChartData } from "@/api/list";
import {
  getAllProcessList,
  showBannerImg,
  getListAssetList,
  addVideoPlayNum,
  getVideoTags,
  getArticleList,
  getArticleTags,
  addArticleNum,
} from "@/api/welcome";
import menuContent from "@/components/menuContent/index1.vue";
import footerMenu from "@/components/footerMenu/index1.vue";
export default {
  name: "home",
  props: {
    //传值
  },
  data() {
    return {
      imgurl: process.env.VUE_APP_BASE_SHOU + "/",
      contViewList: [],
      ClassroomList: [
        {
          title: "最新",
          value: 0,
          active: true,
        },
        {
          title: "热门",
          value: 1,
          active: false,
        },
      ],
      articleist: [
        {
          title: "最新",
          value: 0,
          active: true,
        },
        {
          title: "热门",
          value: 1,
          active: false,
        },
      ],
      articleList: [] /* [
        {
          title: "澳洲肺鱼-Nature",
          introduce:
            "Lungfshes belong to lobe-fned fsh (Sarcopterygii) that, in the Devonian period, ‘conquered’ the land and ultimately gave rise to all land vertebrates, including humans1–3. Here we determine the chromosome-quality genome of the Australian lungfsh (Neoceratodus forsteri), which is known to have the largest genome of any animal. The vast size of this genome, which is about 14× larger than that of humans, is attributable mostly to huge intergenic regions and introns with high repeat content (around 90%), the components of which resemble those of tetrapods (comprising mainly long interspersed nuclear elements) more than they do those of ray-fnned fsh. The lungfsh genome continues to expand independently (its transposable elements are still active), through mechanisms diferent to those of the enormous genomes of salamanders. The 17 fully assembled lungfsh macrochromosomes maintain synteny to other vertebrate chromosomes, and all microchromosomes maintain conserved ancient homology with the ancestral vertebrate karyotype. Our phylogenomic analyses confrm previous reports that lungfsh occupy a key evolutionary position as the closest living relatives to tetrapods4,5, underscoring the importance of lungfsh for understanding innovations associated with terrestrialization. Lungfsh preadaptations to living on land include the gain of limb-like expression in developmental genes such as hoxc13 and sall1 in their lobed fns. Increased rates of evolution and the duplication of genes associated with obligate air-breathing, such as lung surfactants and the expansion of odorant receptor gene families (which encode proteins involved in detecting airborne odours), contribute to the tetrapod-like biology of lungfshes. These fndings advance our understanding of this major transition during vertebrate evolution",
          tag: "基因组",
          num: "476",
        },
        {
          title: "甘薯单倍型基因组，六倍体",
          introduce:
            "Here we present the 15 pseudochromosomes of sweet potato, Ipomoea batatas, the seventh most important crop in the world and the fourth most significant in China. By using a novel haplotyping method based on genome assembly, we have produced a half haplotype-resolved genome from ~296 Gb of paired-end sequence reads amounting to roughly 67-fold coverage. By phylogenetic tree analysis of homologous chromosomes, it was possible to estimate the time of two recent whole-genome duplication events as occurring about 0.8 and 0.5 million years ago. This half haplotype-resolved hexaploid genome represents the first successful attempt to investigate the complexity of chromosome sequence composition directly in a polyploid genome, using sequencing of the polyploid organism itself rather than any of its simplified proxy relatives. Adaptation and application of our approach should provide higher resolution in future genomic structure investigations, especially for similarly complex genomes.",
          tag: "单倍型基因组",
          num: "365",
        },
        {
          title: "同源四倍体甘蔗",
          introduce:
            "Modern sugarcanes are polyploid interspecific hybrids, combining high sugar content from Saccharum officinarum with hardiness, disease resistance and ratooning of Saccharum spontaneum. Sequencing of a haploid S. spontaneum, AP85-441, facilitated the assembly of 32 pseudo-chromosomes comprising 8 homologous groups of 4 members each, bearing 35,525 genes with alleles defined. The reduction of basic chromosome number from 10 to 8 in S. spontaneum was caused by fissions of 2 ancestral chromosomes followed by translocations to 4 chromosomes. Surprisingly, 80% of nucleotide binding site-encoding genes associated with disease resistance are located in 4 rearranged chromosomes and 51% of those in rearranged regions. Resequencing of 64 S. spontaneum genomes identified balancing selection in rearranged regions, maintaining their diversity. Introgressed S. spontaneum chromosomes in modern sugarcanes are randomly distributed in AP85-441 genome, indicating random recombination among homologs in different S. spontaneum accessions. The allele-defined Saccharum genome offers new knowledge and resources to accelerate sugarcane improvement",
          tag: "单倍型基因组",
          num: "378",
        },
        {
          title: "人类完整基因组",
          introduce:
            "Sergey Nurk1,*, Sergey Koren1,*, Arang Rhie1,*, Mikko Rautiainen1,*, Andrey V. Bzikadze2, Alla Mikheenko3,Mitchell R. Vollger4, Nicolas Altemose5, Lev Uralsky6,7, Ariel Gershman8, Sergey Aganezov9, Savannah J.Hoyt10, Mark Diekhans11, Glennis A. Logsdon4, Michael Alonge9, Stylianos E. Antonarakis12, MatthewBorchers13, Gerard G. Bouffard14, Shelise Y. Brooks14, Gina V. Caldas15, Haoyu Cheng16,17, Chen-Shan Chin18, William Chow19, Leonardo G. de Lima13, Philip C. Dishuck4, Richard Durbin21, Tatiana Dvorkina3,Ian T. Fiddes22, Giulio Formenti23,24, Robert S. Fulton25, Arkarachai Fungtammasan18, Erik Garrison11,26,Patrick G.S. Grady10, Tina A. Graves-Lindsay27, Ira M. Hall28, Nancy F. Hansen29, Gabrielle A. Hartley10,Marina Haukness11, Kerstin Howe19, Michael W. Hunkapiller30, Chirag Jain1,31, Miten Jain11, Erich D.Jarvis23,24, Peter Kerpedjiev32, Melanie Kirsche9, Mikhail Kolmogorov33, Jonas Korlach30, Milinn Kremitzki27,Heng Li16,17, Valerie V. Maduro34, Tobias Marschall35, Ann M. McCartney1, Jennifer McDaniel36, Danny E.Miller4,37, James C. Mullikin14,29, Eugene W. Myers38, Nathan D. Olson36, Benedict Paten11, Paul Peluso30,Pavel A. Pevzner33, David Porubsky4, Tamara Potapova13, Evgeny I. Rogaev6,7,39,40, Jeffrey A. Rosenfeld41,Steven L. Salzberg9,42, Valerie A. Schneider43, Fritz J. Sedlazeck44, Kishwar Shafin11, Colin J. Shew20,Alaina Shumate42, Yumi Sims19, Arian F. A. Smit 45, Daniela C. Soto20, Ivan Sović30,46, Jessica M. Storer45,Aaron Streets5,47, Beth A. Sullivan48, Françoise Thibaud-Nissen43, James Torrance19, Justin Wagner36,Brian P. Walenz1, Aaron Wenger30, Jonathan M. D. Wood19, Chunlin Xiao43, Stephanie M. Yan49, Alice C.Young14, Samantha Zarate9, Urvashi Surti50, Rajiv C. McCoy49, Megan Y. Dennis20, Ivan A. Alexandrov 3,7,51,Jennifer L. Gerton13, Rachel J. O'Neill10, Winston Timp",
          tag: "T2T基因组",
          num: "399",
        },
        {
          title: "水稻0gap组装结果",
          introduce:
            "Jia-Ming Song, Wen-Zhao Xie, Shuo Wang, Yi-Xiong Guo, Dal-Hoe Koo, Dave Kudrna, Chenbo Gong, Yicheng Huang, Jia-Wu Feng, Wenhui Zhang, Yong Zhou,Andrea Zuccolo, Evan Long, Seunghee Lee, Jayson Talag, Run Zhou, Xi-TongZhu, Daojun Yuan, Joshua Udall, Weibo Xie, Rod A. Wing, Qifa Zhang, JessePoland, Jianwei Zhang, Ling-Ling Chen",
          tag: "T2T基因组",
          num: "306",
        },
        {
          title: "桃果肉缓慢软化BSA-结合转录组",
          introduce:
            "Identification of candidate genes associated with slow-melting flesh trait in peach using bulked segregant analysis and RNA-seq",
          tag: "重测序",
          num: "350",
        },
        {
          title: "脑结构的转录组关联分析揭示了复杂神经精神特征的多效性",
          introduce:
            "Transcriptome-wide association analysis of brain structures yields insights into pleiotropy with complex neuropsychiatric traits",
          tag: "TWAS",
          num: "651",
        },
        {
          title: "全转录组关联研究的机遇和挑战",
          introduce:
            "Transcriptome-wide association studies (TWAS) integrate genome-wide association studies (GWAS) and gene expression datasets to identify gene–trait associations. In this Perspective, we explore properties of TWAS as a potential approach to prioritize causal genes at GWAS loci, by using simulations and case studies of literature-curated candidate causal genes for schizophrenia, low-density-lipoprotein cholesterol and Crohn’s disease. We explore risk loci where TWAS accurately prioritizes the likely causal gene as well as loci where TWAS prioritizes multiple genes, some likely to be non-causal, owing to sharing of expression quantitative trait loci (eQTL). TWAS is especially prone to spurious prioritization with expression data from non-trait-related tissues or cell types, owing to substantial cross-cell-type variation in expression levels and eQTL strengths. Nonetheless, TWAS prioritizes candidate causal genes more accurately than simple baselines. We suggest best practices for causal-gene prioritization with TWAS and discuss future opportunities for improvement. Our results showcase the strengths and limitations of using eQTL datasets to determine causal genes at GWAS loci.",
          tag: "TWAS",
          num: "407",
        },
      ] */,
      video_list: [],
      bannerList: [],
      activeNames: ["1"],
      List: [
        {
          title: "2022-06-13",
          value: "极智云BSA分析平台上线啦",
        },
        {
          title: "2022-06-13",
          value: "极智云基因组组装平台上线啦",
        },
        {
          title: "2022-06-13",
          value: "极智云基因组组装评估平台上线啦",
        },
        {
          title: "2022-06-13",
          value: "极智云基因功能注释平台上线啦",
        },
        {
          title: "2022-06-13",
          value: "极智云实时绘图工具上线啦",
        },
      ],
      index: 0,
      chaochu: false,
      chaochu1: false,
      flag: true,
      intersectionObserver: null, // 是否元素在可是区域
      intersectionObserverList: [],
    };
  },
  components: {
    //引入模块
    menuContent,
    footerMenu,
    PingtaiJieshao,
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    // console.log()
    if (!this.$Storage.getItem("adm_name")) {
      this.$router.push({ path: "/welcome" });
    }
    this.getGongsiListView();
    this.getPage();
    this.getViedoTag();
    this.getArticleTag();
    this.getArticleData();
    // this.drawChart();
    document.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    jump1(it) {
      console.log(it);

      if (it.lianjie) {
        this.$router.push({ path: it.lianjie });
      }
    },
    paixu(arr) {
      arr = arr.sort((a, b) => {
        return a.paixu - b.paixu;
      });
    },
    getGongsiListView() {
      getGongsiList({}).then((response) => {
        console.log(response.data);
        if (response.data.code == 1) {
          this.contViewList = response.data.data.list;
          this.contViewList = this.contViewList.map((item) => {
            item.img = JSON.parse(item.img);
            item.background_url = JSON.parse(item.background_url);
            return item;
          });
          console.log(this.contViewList);
          this.paixu(this.contViewList);
          this.$nextTick(() => {
            this.initIntersectionObserver();
          });
        } else {
          // this.$message.error(response.data.msg);
        }
      });
    },
    delHandleMouseEnter() {
      this.$nextTick(() => {
        this.flag = false;
      });
    },
    delHandleMouseEnter1() {
      this.$nextTick(() => {
        this.flag = true;
      });
    },
    search(info) {
      for (var a = 0; a < this.ClassroomList.length; a++) {
        this.ClassroomList[a].active = false;
      }
      info.active = true;
      this.page = 1;
      console.log(info);
      this.getPage(info.value);
    },
    searchArticle(info) {
      for (var a = 0; a < this.articleist.length; a++) {
        this.articleist[a].active = false;
      }
      info.active = true;
      this.page = 1;
      console.log(info);
      this.getArticleData(info.value);
    },
    getArticleData(cb = null) {
      var videodata = {
        page: 1,
        pageSize: 8,
      };
      if (cb != null) {
        videodata.tags = cb;
      }
      if (cb == 0 || cb == 1) {
        delete videodata.tags;
      }
      if (cb == 1) {
        videodata.is_hot = 2;
      }
      getArticleList(videodata).then((response) => {
        console.log(response.data);
        if (response.data.code == 1) {
          response.data.data.list.forEach((item) => {
            item.tags = item.tags.substring(1, item.tags.length - 1);
          });
          this.articleList = response.data.data.list;
        } else {
          // this.$message.error(response.data.msg);
          this.$message({
            showClose: false,
            message: response.data.msg,
            type: "error",
            offset: "100",
          });
        }
      });
    },
    getViedoTag() {
      getVideoTags().then((response) => {
        console.log(response.data);
        if (response.data.code == 1) {
          response.data.data.forEach((item) => {
            item.value = item.title;
            item.active = false;
            this.ClassroomList.push(item);
          });
          for (var a = 0; a < this.ClassroomList.length; a++) {
            // if(a>13){
            //   this.ClassroomList[a].hide=false
            // }else{
            this.ClassroomList[a].hide = true;
            // }
          }
          this.$forceUpdate();
          /* this.$nextTick(() => {
            console.log(this.$refs.tag.offsetWidth,this.$refs.tag.offsetHeight)
            if (this.$refs.tag.offsetHeight > 30) {
              for (var a = 0; a < this.ClassroomList.length; a++) {
                if (a < 5) {
                  this.ClassroomList[a].hide = true;
                } else {
                  this.ClassroomList[a].hide = false;
                }
              }
              this.$forceUpdate()
            }
            //  this.$forceUpdate()
          }); */
        } else {
          // this.$message.error(response.data.msg);
          this.$message({
            showClose: false,
            message: response.data.msg,
            type: "error",
            offset: "100",
          });
        }
      });
    },
    getArticleTag() {
      getArticleTags().then((response) => {
        console.log(response.data);
        if (response.data.code == 1) {
          response.data.data.forEach((item) => {
            item.value = item.title;
            item.active = false;
            this.articleist.push(item);
          });
          for (var a = 0; a < this.articleist.length; a++) {
            // if(a>13){
            //   this.ClassroomList[a].hide=false
            // }else{
            this.articleist[a].hide = true;
            // }
          }
          this.$forceUpdate();
          /*  this.$nextTick(() => {
            console.log(this.$refs.tag.offsetWidth,this.$refs.tag.offsetHeight)
            if (this.$refs.tag.offsetHeight > 30) {
              for (var a = 0; a < this.ClassroomList.length; a++) {
                if (a < 14) {
                  this.ClassroomList[a].hide = true;
                } else {
                  this.ClassroomList[a].hide = false;
                }
              }
              this.$forceUpdate()
            }
            //  this.$forceUpdate()
          }); */
        } else {
          // this.$message.error(response.data.msg);
          this.$message({
            showClose: false,
            message: response.data.msg,
            type: "error",
            offset: "100",
          });
        }
      });
    },
    zhankai() {
      /* if(!this.chaochu){
       
         for (var a = 0; a < this.ClassroomList.length; a++) {
                // if (a < 5) {
                  this.ClassroomList[a].hide = true;
                // } else {
                //   this.ClassroomList[a].hide = false;
                // }
              }
      }else{
         for (var a = 0; a < this.ClassroomList.length; a++) {
                if (a < 14) {
                  this.ClassroomList[a].hide = true;
                } else {
                  this.ClassroomList[a].hide = false;
                }
              }
      }
      this.$forceUpdate() */
      this.chaochu = !this.chaochu;
    },
    zhankai1() {
      /*  if(!this.chaochu1){
       
         for (var a = 0; a < this.articleist.length; a++) {
                // if (a < 5) {
                  this.articleist[a].hide = true;
                // } else {
                //   this.ClassroomList[a].hide = false;
                // }
              }
      }else{
         for (var a = 0; a < this.articleist.length; a++) {
                if (a < 14) {
                  this.articleist[a].hide = true;
                } else {
                  this.articleist[a].hide = false;
                }
              }
      }
      this.$forceUpdate() */
      this.chaochu1 = !this.chaochu1;
    },
    //给app传值
    carouselChange(e) {
      this.$emit("carouselChange", this.bannerList[e]);
    },
    more(type) {
      if (type == "视频") {
        this.$router.push({ path: "/Product/training" });
      }
      if (type == "文献") {
        this.$router.push({ path: "/Product/articleView" });
      }
    },
    // 组件的方法
    videoPlayer(o) {
      console.log(o);
      var videodata = {
        asset_id: o.asset_id,
        type: "play",
      };
      addVideoPlayNum(videodata).then((response) => {
        console.log(response.data);
        if (response.data.code == 1) {
        } else {
          // this.$message.error(response.data.msg);
          this.$message({
            showClose: false,
            message: response.data.msg,
            type: "error",
            offset: "100",
          });
        }
      });
      this.$router.push({ name: "videoPage", params: { info: o } });
      /*   hwplayerloaded(function () {
        var player = new HWPlayer(
          "test",
          {
             width: 546,
            height: 300,
            controls: true,
          },
          function () {
            //播放器加载完成执行的逻辑
          }
        );
        player.src({
          src: o.video_url,
          type: "video/mp4",
        });
      });
     */
    },
    rgba(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var context = canvas.getContext("2d");
      img.crossOrigin = "Anonymous";
      context.drawImage(img, 0, 0, canvas.width, canvas.height);

      // 获取像素数据
      var data = context.getImageData(0, 0, img.width, img.height).data;
      // console.log(data);
      var r = 1,
        g = 1,
        b = 1;
      // 取所有像素的平均值
      for (var row = 0; row < img.height; row++) {
        for (var col = 0; col < img.width; col++) {
          // console.log(data[((img.width * row) + col) * 4])
          if (row == 0) {
            r += data[img.width * row + col];
            g += data[img.width * row + col + 1];
            b += data[img.width * row + col + 2];
          } else {
            r += data[(img.width * row + col) * 4];
            g += data[(img.width * row + col) * 4 + 1];
            b += data[(img.width * row + col) * 4 + 2];
          }
        }
      }

      // console.log(r, g, b);
      // 求取平均值
      r /= img.width * img.height;
      g /= img.width * img.height;
      b /= img.width * img.height;

      // 将最终的值取整
      r = Math.round(r);
      g = Math.round(g);
      b = Math.round(b);
      // console.log(r, g, b);

      return [r, g, b];
    },
    bannerImage(e) {
      let img = new Image();

      img.crossOrigin = "";
      img.src = e.img_name;
      img.src = e.img_name + "?time=" + new Date().valueOf();
      // 允许跨域操作
      img.setAttribute("crossOrigin", "anonymous");
      var that = this;
      img.onload = function () {
        const rgb = that.rgba(img);
        // console.log(rgb);
        // const RgbValue = rgb.replace('rgb(', '').replace(')', '');
        const RgbValueArry = rgb;
        const grayLevel =
          RgbValueArry[0] * 0.299 + RgbValueArry[1] * 0.587 + RgbValueArry[2] * 0.114;
        // console.log(grayLevel);
        if (grayLevel >= 175) {
          // console.log("浅色");
          e.back = "浅色";
        } else {
          e.back = "深色";
          // console.log("深色");
        }
      };
    },
    getPage(cb = null) {
      var videodata = {
        page: 1,
        pageSize: 8,
      };
      if (cb != null) {
        videodata.tags = cb;
      }
      if (cb == 0 || cb == 1) {
        delete videodata.tags;
      }
      if (cb == 1) {
        videodata.is_hot = cb;
      }
      getListAssetList(videodata).then((response) => {
        console.log(response.data);
        if (response.data.code == 1) {
          response.data.data.list.forEach((item) => {
            item.tags = item.tags.substring(1, item.tags.length - 1);
          });
          this.video_list = response.data.data.list;
        } else {
          // this.$message.error(response.data.msg);
          this.$message({
            showClose: false,
            message: response.data.msg,
            type: "error",
            offset: "100",
          });
        }
      });
      showBannerImg().then((response) => {
        console.log(response);
        if (response.data.code == 1) {
          this.bannerList = response.data.data;
          for (var a = 0; a < this.bannerList.length; a++) {
            this.bannerImage(this.bannerList[a]);
          }

          this.$emit("carouselChange", this.bannerList[0]);
        } else {
          // this.$message.error(response.data.msg);
          this.$message({
            showClose: false,
            message: response.data.msg,
            type: "error",
            offset: "100",
          });
        }
      });
      //   var data = {
      //     customer_id: this.$Storage.getItem("adm_id"),
      //   };
      //   var that = this;
      //   getPieChartData(data).then((response) => {
      //     console.log(response);
      //     if (response.data.code == 1) {
      //       //           response.data.data[0]=[{name: "基因组组装",value: 1},{name: "任务完成",
      //       // value: 1,}]
      //       // response.data.data=[]
      //       /*  that.drawChart(
      //         response.data.data.length != 0
      //           ? response.data.data
      //           : [
      //               {
      //                 name: "未生成任务",

      //                 value: 0,
      //               },
      //             ]
      //       ); */
      //     } else {
      //       // this.$message.error(response.data.msg);
      //       this.$message({
      //         showClose: false,
      //         message: response.data.msg,
      //         type: "error",
      //         offset: "100",
      //       });
      //     }
      //   });
    },
    // randomColor() {
    //    var col = "#";
    //    for (var i = 0; i < 6; i++) col+=parseInt(Math.random() * 16).toString(16);
    //    return col;
    // },
    drawChart(data) {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("canvas"));

      // var colorList=[]
      // for(var a=0;a<100;a++){
      //   colorList.push(this.randomColor())
      // }
      // console.log(colorList)
      // 指定图表的配置项和数据
      let option = {
        color: [
          "#FF0000",
          "#00FF00",
          "#0000FF",
          "#FF00FF",
          "#00FFFF",
          "#FFFF00",
          "#70DB93",
          "#5C3317",
          "#9F5F9F",
          "#B5A642",
          "#D9D919",
          "#A67D3D",
          "#8C7853",
          "#A67D3D",
          "#5F9F9F",
          "#D98719",
          "#B87333",
          "#FF7F00",
          "#42426F",
          "#5C4033",
          "#2F4F2F",
          "#4A766E",
          "#4F4F2F",
          "#9932CD",
          "#871F78",
          "#6B238E",
          "#2F4F4F",
          "#97694F",
          "#7093DB",
          "#855E42",
          "#545454",
          "#856363",
          "#D19275",
          "#8E2323",
          "#238E23",
          "#CD7F32",
          "#DBDB70",
          "#C0C0C0",
          "#527F76",
          "#93DB70",
          "#215E21",
          "#4E2F2F",
          "#9F9F5F",
          "#C0D9D9",
          "#A8A8A8",
          "#8F8FBD",
          "#E9C2A6",
          "#32CD32",
          "#E47833",
          "#8E236B",
          "#32CD99",
          "#3232CD",
          "#6B8E23",
          "#EAEAAE",
          "#9370DB",
          "#426F42",
          "#7F00FF",
          "#7FFF00",
          "#70DBDB",
          "#DB7093",
          "#A68064",
          "#2F2F4F",
          "#23238E",
          "#4D4DFF",
          "#FF6EC7",
          "#00009C",
          "#EBC79E",
          "#CFB53B",
          "#FF7F00",
          "#FF2400",
          "#DB70DB",
          "#8FBC8F",
          "#BC8F8F",
          "#EAADEA",
          "#D9D9F3",
          "#5959AB",
          "#6F4242",
          "#BC1717",
          "#238E68",
          "#6B4226",
          "#8E6B23",
          "#E6E8FA",
          "#3299CC",
          "#007FFF",
          "#FF1CAE",
          "#00FF7F",
          "#236B8E",
          "#38B0DE",
          "#DB9370",
          "#D8BFD8",
          "#ADEAEA",
          "#5C4033",
          "#CDCDCD",
          "#4F2F4F",
          "#CC3299",
          "#D8D8BF",
          "#99CC",
        ],
        tooltip: {
          trigger: "item",
          textStyle: {
            color: "#000000",
          },
          formatter: function (a, b, c, d) {
            console.log(a, b, c, d, 11111);
            var cb = `${a["seriesName"]}<br/>${a.marker} ${a["name"]}: ${a["value"]}`;
            for (var key in a.data) {
              if (key == "status_name1") {
                //正在排队
                cb +=
                  "<br/> <span style='display:inline-block;margin-left:10px;margin-right:4px;'>-</span>" +
                  `${a.data["status_name1"]}: ${a.data["status_num1"]}`;
              }
              if (key == "status_name2") {
                //正在运行
                cb +=
                  "<br/> <span style='display:inline-block;margin-left:10px;margin-right:4px;'>-</span>" +
                  `${a.data["status_name2"]}: ${a.data["status_num2"]}`;
              }
              if (key == "status_name3") {
                //任务完成
                cb +=
                  "<br/> <span style='display:inline-block;margin-left:10px;margin-right:4px;'>-</span>" +
                  `${a.data["status_name3"]}: ${a.data["status_num3"]}`;
              }
              if (key == "status_name4") {
                //任务失败
                cb +=
                  "<br/> <span style='display:inline-block;margin-left:10px;margin-right:4px;'>-</span>" +
                  `${a.data["status_name4"]}: ${a.data["status_num4"]}`;
              }
            }
            return cb;
          },
        },
        title: {
          text: "个人任务总览",
          subtext: "",
          left: "left",
        },
        /* grid: {
          top: "20%",
        }, */
        legend: {
          selectedMode: false,
          type: "scroll",
          /* top: "10%",
          left: "center", */
          orient: "vertical",
          right: 10,
          top: "10%",
          bottom: "20%",
        },
        series: [
          {
            name: "我的任务",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["40%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            waveAnimation: false, //停止动画 动画占用cpu过高 核心代码
            animationDuration: 0, //停止动画 动画占用cpu过高 核心代码
            animationDurationUpdate: 0, //停止动画 动画占用cpu过高 核心代码
            data: data,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);

      myChart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: 0,
      });
      // 当鼠标移入时，如果不是第一项，则把当前项置为选中，如果是第一项，则设置第一项为当前项
      myChart.on("mouseover", function (e) {
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: 0,
        });
        if (e.dataIndex != this.index) {
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: this.index,
          });
        }
        if (e.dataIndex == 0) {
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: e.dataIndex,
          });
        }
      });

      //当鼠标离开时，把当前项置为选中
      myChart.on("mouseout", function (e) {
        this.index = e.dataIndex;
        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
      });

      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    /*  */
    handleChange(val) {
      console.log(val);
    },
    lookArticle(item) {
      var videodata = {
        id: item.id,
      };
      addArticleNum(videodata).then((response) => {
        console.log(response.data);
        if (response.data.code == 1) {
        } else {
          // this.$message.error(response.data.msg);
          this.$message({
            showClose: false,
            message: response.data.msg,
            type: "error",
            offset: "100",
          });
        }
      });
      this.$router.push({ name: "article", params: { info: item } });
    },
    handleScroll(e) {
      // console.log(e.target,document.querySelector("#main"))
      if (e.target == document.querySelector("#main")) {
        var top = e.target.scrollTop;
        this.top = top;
      }
    },
    initIntersectionObserver() {
      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          let entrie = entries;
          entrie.forEach((el) => {
            console.log(el);
            if (el.isIntersecting) {
              this.intersectionObserverList.push(Number(el.target.id));
            }
          });
          // if(!entrie.intersectionRatio)return false;
        },
        {
          threshold: 0.3, // 阀值设为1，当只有比例达到1时才触发回调函数
        }
      );
      var items = [
        ...document.getElementsByClassName("content1"),
        ...document.getElementsByClassName("content2"),
      ]; // 解析为真数组，也可用 Array.prototype.slice.call()
      console.log(items);
      items.forEach((ele) => {
        console.log(ele, "222");
        this.intersectionObserver.observe(ele);
      });
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>

<style scoped>
.headertwo {
  color: #fff;
  box-shadow: none;
  /*  transition: 0.4s background-color, color 0.4s; */
  /* background: #0081c6; */
  background: #0083b0;
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 70px !important;
  line-height: 70px;
  padding: 0 240px;
}
.headerback {
  background: #0081c6 !important;
}
.headerBox {
  /* height: 830px !important; */
  position: fixed;
  background: rgba(0, 0, 0, 0.05);
  z-index: 10000;
  width: 100%;
  height: 70px !important;
  line-height: 70px;
  padding: 0 240px;
}

.imgBox img {
  width: auto;
  height: 38px;
  position: absolute;
  top: 50%;
  margin-top: -19px;
}
.search {
  width: 40px;
  height: 40px;
  color: #ffffff;
  opacity: 1;
  background: transparent;
  border: 0;
  position: absolute;
  right: 100px;
  top: 15px;
  padding: 0;
  /*  margin-top: -20px; */
}
.login {
  width: 100px;
  height: 70px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -35px;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
}
.menuBox {
  width: 100%;
  /* height: 70px; */
  position: absolute;
  right: 50%;
  margin-right: -50%;
  top: 0.048125rem;
}
.bannerBox {
  position: relative;
  margin-bottom: 110px;
}
.bannerBoxTop >>> .el-carousel__container {
  margin-top: 70px;
}
.bannerBox .bannerMenu {
  position: absolute;
  top: 168px;
  left: 240px;
  z-index: 1000;
}
.mainBox {
  padding: 40px 240px;
}
.TitleBox {
  height: 43px;
}
.tagBox {
  /* float: left; */
  /* margin: 0 0 0 32px; */
  /*  display: flex;
  align-items: flex-start;
  justify-content: space-between; */
  width: 1100px;
  position: relative;
}
.zhankaiBtn {
  position: absolute;
  right: -50px;
  top: 5px;
  height: 30px;
  line-height: 10px;
}
.tagChaochu {
  width: 500px;
  height: 40px;
  overflow: hidden;
}
.Title {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: var(--Color);

  margin-bottom: 14px;

  font-weight: 500;
}
.more {
  float: right;
  margin-right: 27px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.tagMarginRight {
  margin-right: 24px;
  cursor: pointer;
}
.tag-active {
  background: #2589ff;
  color: #fff;
  margin-top: 7px;
  margin-bottom: 6px;
}
.tag {
  background: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 7px;
  margin-bottom: 6px;
}
.boxView {
  margin: 32px 0;
}
.cartag {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
  line-height: 22px;
}
.carNum {
  float: right;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
  line-height: 22px;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
}
.introduce {
  background: #f7f8fb;
  padding: 10px 16px;
  border-radius: 5px;
}
.button {
  padding: 0;
  float: right;
}
.carTitle {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;
  width: 100%;
  height: 48px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.carTitleIntroduce {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 22px;
  width: 100%;
  height: 44px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.bannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image {
  width: 100%;
  height: 231px;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.cardBox {
  transition: all 0.5s;
  cursor: pointer;
  width: 100%;
  position: relative;
}
.tagsView {
  background: #f1f2f3;
  border-radius: 10px;
  padding: 5px;
  color: #61666d;
  margin-left: 5px;
}
.tagsView:first-child {
  margin-left: 0;
}
.tagsView1 {
  background: #f1f2f3;
  border-radius: 10px;
  padding: 5px;
  color: #61666d;
  margin-bottom: 5px;
}
.tagsView1:last-child {
  margin-bottom: 0;
}
.tagBoxChildren {
  overflow: hidden;
  height: 40px;
}
</style>
<style scoped>
.content1 {
  background-color: var(--backgroundColor);
  height: 420px;
  width: 1200px;
  margin: 110px auto;
}
.content2 {
  height: 630px;
  width: 100%;
  margin: 0px auto;
}
.djht {
  justify-content: center;
  cursor: pointer;
  background: #f9fafc;
  /* margin-bottom: 33px; */
  width: 100%;
  height: 243px;
  text-align: center;
  font-size: 20px;
  display: flex;
  align-items: center;
  /* display: table-cell;
    vertical-align: middle; */
}

.djht:hover {
  box-shadow: 0px 0px 22px 0px rgba(174, 203, 238, 0.6);
}
.djhtimg {
  width: 100%;
}
.jbcol {
  padding: 55px 0px 56px 0px;
}
.jieshao {
  white-space: pre-line;
  width: 435px;
  height: 290px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212223;
  line-height: 36px;
  text-align: justify;
}

.jizhiyun {
  margin-top: 50px;
}
.imgleft {
  display: block !important;
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.imgright {
  display: block !important;
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.imgdown {
  display: block !important;
  -webkit-animation: slide-in-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  margin-bottom: 33px;
}
.imgBoxcon {
  width: 100%;
  display: none;
}

.imgdown1 {
  display: none;
}
.djht:hover {
  transform: scale(1.03);
}

.srcimg {
  width: 130px;
  height: 130px;
  /* padding: 24px 124px 24px 124px; */
  /* padding: 0 124px; */
  margin-bottom: 24px;
}
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-in-down {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-down {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
