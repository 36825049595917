import {
	Message
} from 'element-ui';
import {
	downloadExampleFile
} from "@/api/list";
export default {
	methods: {
		successMsg(response) {
			Message({
				showClose: false,
				message: response.data && response.data.msg ? response.data.msg : response,
				type: "success",
				offset: "100",
			})
		},
		errorMsg(response) {
			Message({
				showClose: false,
				message: response.data && response.data.msg ? response.data.msg : response,
				type: "error",
				offset: "100",
			})
		},
		verifyTaskName(taskName, taskObj, callbackFunction,lange='zh') {
			var json={
				zh:"请输入以字母开头，只包含数字、字母、连接符的任务名称！",
				en:"Please enter a task name starting with a letter and consisting only of numbers, letters, and hyphens."
			}
			let reg = /^[a-zA-Z][a-zA-Z0-9-]*$/;
			if (!reg.test(taskName)) {
				taskObj.errormassage = json[lange]
				taskObj.show = true
				callbackFunction(true)
			} else {
				taskObj.show = false
				callbackFunction(false)
			}
		},
		// bsa-售后服务 格式化json数据
		getJson(form, arr, callbackFunction) {
			if (form.flag == '1') {
				delete form.file
			} else {
				delete form.info
			}
			var json = {},
				jsonList = JSON.parse(JSON.stringify(form))
			for (var key in jsonList) {
				if (arr[key] == "上传文件") {
					json[key] = {
						value: [{
							name: jsonList[key],
							label: arr[key]
						}],
						label: arr[key]
					}
				} else if (arr[key]) {
					json[key] = {
						value: jsonList[key],
						label: arr[key]
					}
				}
			}
			console.log(json)
			callbackFunction(json);
		},
		// bsa-售后服务，下载示例文件
		downloadExampleFile(name) {
			var data = {
				example_name: name
			}
			downloadExampleFile(data).then((res) => {
				if (res.data.code == 1) {
					console.log("1111")
					this.successMsg('正在下载示例文件...')
					this.downloadEvt(res.data.data)
					// let a = document.createElement('a') //创建一个a标签元素
					// a.style.display = 'none' //设置元素不可见
					// a.href = res.data.data //设置下载地址
					// document.body.appendChild(a) //加入
					// reture
					// a.click() //触发点击,下载
					// document.body.removeChild(a) //释放 
				} else {
					this.errorMsg(res)
				}
			})
		},
		downloadEvt(url, fileName = "未知文件.txt") {
			const el = document.createElement("a");
			el.style.display = "none";
			var url_name = url.substring(url.lastIndexOf("/") + 1);
			el.setAttribute("target", "_top");//d点击下载时直接下载 无需等待加载_top
			 el.href = process.env.VUE_APP_BASE_API+"/downloadExampleFileNew/"+url_name;
			  document.body.appendChild(el);
			  el.click();
			  document.body.removeChild(el);
			return
		
			if (url_name.indexOf(".txt") != -1) {
				fetch(url,{method: 'GET',mode: 'no-cors',})
					.then((res) => res.blob())
					.then((blob) => {
						el.href = URL.createObjectURL(blob);
						el.download = url_name;
						document.body.appendChild(el);
						el.click();
						console.log(el)
						document.body.removeChild(el);
					});
			} else {
				el.setAttribute('target', '_blank');
				/**
				 * download的属性是HTML5新增的属性
				 * href属性的地址必须是非跨域的地址，如果引用的是第三方的网站或者说是前后端分离的项目(调用后台的接口)，这时download就会不起作用。
				 * 此时，如果是下载浏览器无法解析的文件，例如.exe,.xlsx..那么浏览器会自动下载，但是如果使用浏览器可以解析的文件，比如.txt,.png,.pdf....浏览器就会采取预览模式
				 * 所以，对于.txt,.png,.pdf等的预览功能我们就可以直接不设置download属性(前提是后端响应头的Content-Type: application/octet-stream，如果为application/pdf浏览器则会判断文件为 pdf ，自动执行预览的策略)
				 */
				fileName && el.setAttribute('download', fileName);
				el.href = url;
				console.log(el);
				document.body.appendChild(el);
				el.click();
				document.body.removeChild(el);
			}
		},
		// 格式化表格数据，并用下划线替换特殊字符
		formTableData(data,id,callbackFunction){
			console.log(data.data)
			if( data.data){
				var Data = data.data.map(item => {
					for (var key in item) {
						var str=key
						str=str.replace(/\./g, "_").replace(/\^/g, "_").replace(/\s/g, "_")
						// if (key.indexOf(".") != -1) {
						// 	var str = key.replace(/\./g, "_")
						// 	item[str] = item[key]
						// 	delete item[key]
						// }
						// if (key.indexOf("^") != -1) {
						// 	var str = key.replace(/\^/g, "_")
						// 	item[str] = item[key]
						// 	delete item[key]
						// }
						// if (key.indexOf(" ") != -1) {
						// 	var str = key.replace(/\s/g, "_")
							item[str] = item[key]
							// delete item[key]
						// }
					}
					return item
				})
			}
			if(data.table){
				var Data = data.table.map(item => {
					for (var key in item) {
						var str=key
						str=str.replace(/\./g, "_").replace(/\^/g, "_").replace(/\s/g, "_")
						// if (key.indexOf(".") != -1) {
						// 	var str = key.replace(/\./g, "_")
						// 	item[str] = item[key]
						// 	delete item[key]
						// }
						// if (key.indexOf("^") != -1) {
						// 	var str = key.replace(/\^/g, "_")
						// 	item[str] = item[key]
						// 	delete item[key]
						// }
						// if (key.indexOf(" ") != -1) {
						// 	var str = key.replace(/\s/g, "_")
							item[str] = item[key]
							// delete item[key]
						// }
					}
					return item
				})
			}
			
			// if(id==97){
			// 	var heard = data.columns.map((item,index) => {
			// 		var json={
			// 			width: 'auto',
			// 			prop: item.replace(/\./g, "_").replace(/\^/g, "_").replace(/\s/g, "_"),
			// 			label: "name"+index
			// 		}
			// 		switch(index){
			// 			case 0:
			// 				json.label="Query id"
			// 			break;
			// 			case 1:
			// 				json.label="Subject id"
			// 			break;
			// 			case 2:
			// 				json.label="% identity"
			// 			break;
			// 			case 3:
			// 				json.label="Query id"
			// 			break;
			// 			case 4:
			// 				json.label="alignment length"
			// 			break;
			// 			case 5:
			// 				json.label="mismatches"
			// 			break;
			// 			case 6:
			// 				json.label="gap openings"
			// 			break;
			// 			case 7:
			// 				json.label="q. start"
			// 			break;
			// 			case 8:
			// 				json.label="q. end"
			// 			break;
			// 			case 9:
			// 				json.label="s. start"
			// 			break;
			// 			case 10:
			// 				json.label="s. end"
			// 			break;
			// 			case 10:
			// 				json.label="s. end"
			// 			break;
			// 		}
			// 		return json
			// 	})
			// }else{
			if(data.columns){
				var heard = data.columns.map(item => {
					return {
						width: 'auto',
						prop: item.replace(/\./g, "_").replace(/\^/g, "_").replace(/\s/g, "_"),
						label: item
					}
				})
			}
			if(data.head){
				var heard = data.head.map(item => {
					return {
						width: 'auto',
						prop: item.replace(/\./g, "_").replace(/\^/g, "_").replace(/\s/g, "_"),
						label: item
					}
				})
			}
				
			// }
		
			callbackFunction(Data,heard)
		}


	}
}
