import request from '@/utils/request'
import md5 from "js-md5"
// 登录
export function Login(data) {
    var data = {
        username: data.email,
        password: md5(data.password),
    }
    return request({
        url: '/login',
        method: 'post',
        data
    })
}

// 注册
export function Register(data) {
    var data = {
        name: data.name,
        username:data.email,
        password: md5(data.password),
        tel:data.phone,
        oemail:data.email,
    }
    return request({
        url: '/register',
        method: 'post',
        data
    })
}
// 获取验证码
export function getCode(data) {
    var data = {
        username:data.email,
    }
    return request({
        url: '/getCode',
        method: 'post',
        data
    })
}
// 重置密码
export function resetPassword(data) {
    var data = {
        username:data.email,
        code:data.fg
    }
    return request({
        url: '/resetPassword',
        method: 'post',
        data
    })
}
// 修改密码
export function updatePassword(data) {
    var data = {
        username:data.email,
        password: md5(data.passwd),
    }
    return request({
        url: '/updatePassword',
        method: 'post',
        data
    })
}
// 获取个人数据
export function getPersonalData(data) {
    return request({
        url: '/getPersonalData',
        method: 'post',
        data
    })
}
// 修改个人数据
export function updatePersonalData(data) {
    return request({
        url: '/updatePersonalData',
        method: 'post',
        data
    })
}