<template>
  <div id="app" v-if="isRouterAlive">
    <el-container>
      <!-- 头部 -->
     <!--  <el-header v-if="routeData.indexOf($route.name) === -1" class="topNav">
        <top-nav />
       
      </el-header> -->
       <el-header  v-if="routeData.indexOf($route.name) === -1"    :class="['', top <= 100 ? 'headerBox' : 'headertwo']"
      :style="'background:#fff'"><!-- back=='深色'?'': -->
       <top-nav :top="top"/>
    </el-header>
      <!-- 菜单 -->
      <el-container>
        <!-- <el-aside
          width="1.041667rem"
          v-if="routeData.indexOf($route.name) === -1"
          class="navBox"
        > -->
          <!-- 菜单组件 -->
        <!--   <menu-tab /> -->
       <!--  </el-aside> -->
        <!-- 内容部分 -->
        <el-main
          id="main"
          class="mainview"
          :class="
            routeData1.indexOf($route.name) === -1 ? 'mainHeight' : 'overHeight'
          "
         
        >
          <!-- 展示路由 -->
          <keep-alive exclude='test'>
            <router-view
            v-if="$route.meta.keepAlive"
            :class="
              routeData1.indexOf($route.name) === -1 ? 'overPading1' : 'overPading'
            "  @carouselChange="carouselChange"
          ></router-view>
          </keep-alive>
           <router-view
            v-if="!$route.meta.keepAlive"
            :class="
              routeData1.indexOf($route.name) === -1 ? 'overPading1' : 'overPading'
            "  @carouselChange="carouselChange"
          ></router-view>
          <!-- <foot-nav v-if="routeData.indexOf($route.name) === -1" class="footBox"/> -->
            <div class="extra-wrap" v-if="$route.path!='/Feedback'&&routeData.indexOf($route.name) === -1">
              <el-button type="text" class="feedback-btn extra-btn" @click="jump">意见<br>反馈</el-button>
          </div>
           <!-- <footer-menu v-if="routeData1.indexOf($route.name) === -1" :class="routeData2.indexOf($route.name)===-1?'footBox':'footBox1'"></footer-menu> -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import footerMenu from "@/components/footerMenu/index1.vue";
import TopNav from "@/components/TopNav/index.vue";
import MenuTab from "@/components/MenuTab/index.vue";
export default {
  name: "app",
  provide() {
    // 注册一个方法
    return {
      reload: this.reload, //  页面刷新
    };
  },
  data() {
    return {
      //需要占满整个屏幕的路由
      routeData: ["login",'welcome'],
      routeData1: ["login",'welcome','home'],
      routeData2: ['personal'],
      isRouterAlive: true,
      back:"深色",
      top:0
    };
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
    $route:'fetchData'
  },
   computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
   
  },
  mounted:function (){
    console.log(localStorage.getItem("Admin-Token"),"5555")
    var obj = JSON.parse(localStorage.getItem('adm_email'))  
    var nowTime = new Date().getTime()  //获取当前时间
    var endTime = obj.startTime+ obj.expires;  //获取过期时间
    console.log('当前时间',nowTime)
    console.log('过期时间',endTime)
    if(nowTime > endTime){  //判断当前时间搓是否大于过期时间
        console.log('已过期'); 
        localStorage.removeItem('Admin-Token');  //删除本地
    
    }
    if(!localStorage.getItem("Admin-Token")){
      this.$router.push({path:"/welcome"})
      return
    }
    this.fetchData()
     document.addEventListener("scroll", this.handleScroll, true);
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "margin:0;");
  },
  components: {
    TopNav,
    MenuTab,
    footerMenu
    /* TopNav,
    MenuTab,
    FootNav */
  },
  methods: {
    jump(){
      this.$router.push({path:"/Feedback"})
    },
    fetchData(e){
       const { meta, path } = this.$route;
      console.log(path, 111);
      if(path!="/home"){
        this.back="浅色"
      }
      document.querySelector("#main").scrollTop = 0;
      // document.body.scrollTop = 0;

    },
    handleScroll(e) {
      // console.log(e.target,document.querySelector("#main"))
      if (e.target == document.querySelector("#main")) {
         console.log(e.target.scrollTop)
        var top = e.target.scrollTop;
        this.top = top;
       
      }
    },
    carouselChange(e){
      console.log(e)
      this.back=e.back
    },
    
    //刷新功能
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  font-family: "Microsoft YaHei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  font-size: 16px;
  color: #2c3e50;
}
.mainHeight {
  background: #F7F8FB;
  //  margin-bottom: 70px;
}
.overHeight {
  height: calc(100vh - 0rem);
}
.pading {
  min-height: calc(100vh - 387px);
  margin: 20px 20px !important;
}
.overPading {
  padding: 0 !important;
}
.overPading1 {
  padding: 90px 240px 0 240px !important;
}
.navBox {
  /* background: red; */
  background: #0c8bae;

  /* height: 200px;
  height: calc(100vh - 80px) !important; */
}
.topNav {
  position: fixed;
  width: 100%;
  z-index: 1000;
  /* background: #0c8bae; */
  height: 70px !important;
 padding: 0 240px;
}
.footBox {
  background: #fff;
  position: absolute;
    width: 100%;
    bottom: 0;
 /*  margin-top: 30px; */
 /*  position: absolute;
  bottom: 0;
   width: 100vw; */
}
.footBox1 {
  background: #F7F8FB;
 /*  margin-top: 30px; */
 /*  position: absolute;
  bottom: 0;
   width: 100vw; */
}
</style>
<style>
.intro-content1{
  text-indent: 2em;
  margin-left: 2em;
    font-size: 15px;
    line-height: 2em;
    /* padding: 20px 0; */
    margin-top: 10px;
   

}
  a{
    text-decoration: none;
    color: #0000ee;
  }
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
#app,
.box {
  height: 100%;
}
.el-container {
  height: 100%;
}

.el-form-item__label {
  font-size: 16px !important;
}
.el-input--medium {
  font-size: 16px !important;
}
/* .el-header {
  padding: 0;
  color: #fff;
  text-align: center;
  height: 136px !important;
  line-height: 136px;
  
} */
.el-aside {
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
}

.mainview {
  margin: 0;
  padding: 0 !important;
  color: #333;
  /*  height: calc(100vh - 445px); */
  background-color: #fff;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 8px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 3px;
  box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
  background: rgba(123, 194, 235, 1);
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
  border-radius: 3px;
  background: #ededed;
}
.headertwo {
  color: #fff;
  box-shadow: none;
 /*  transition: 0.4s background-color, color 0.4s; */
  background: #0083B0;
  /* background: #0081c6; */
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 70px !important;
  line-height: 70px;
  padding: 0 240px;
}
.headerBox {
  /* height: 830px !important; */
  position: fixed;
  background: rgba(0, 0, 0, 0.05);
  z-index: 10000;
  width: 100%;
  height: 70px !important;
  line-height: 70px;
  padding: 0 240px;
}
</style>
<style scoped>
.extra-wrap {
    position: fixed;
    _position: absolute;
    right: 8px;
    z-index: 99;
    bottom: 100px; 
    display: block;
}

.extra-btn {
    border: 1px solid #bbb;
    border-radius: 2px;
    font-family: Arial,PingFang SC,Hiragino Sans GB,STHeiti,Microsoft YaHei,WenQuanYi Micro Hei,sans-serif;
    height: 40px;
    margin: 10px 0 0;
    width: 40px;
}
.feedback-btn:hover {
   border: 1px solid #bbb;
    border-radius: 2px;
    background-color: #f8f8f8;
    /* text-decoration: none; */
}
.feedback-btn {
    background-color: #fff;
    height: 50px;
    line-height: 18px;
    padding: 6px 7px;
    width: 50px;
}
</style>
<style scoped>
.headerBox {
  /* height: 830px !important; */
  position: fixed;
  background: rgba(0, 0, 0, 0.05);
  z-index: 10000;
  width: 100%;
  height: 70px !important;
  line-height: 70px;
  padding: 0 240px;
}
</style>
<style>
.el-image-viewer__wrapper{
  z-index: 10000 !important;
}
</style>
