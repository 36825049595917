import Vue from 'vue'
import Vuex from 'vuex'
import user from './user';
Vue.use(Vuex)

export default new Vuex.Store({

  modules:{
    user
  },
  state: {
    loading:{
      lock: true,
      text: '加载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    },
  },
  getters: {
  },
  mutations: {
    // showLoading(state) {
    //   state,loadingShow = true
    // },
    // hideLoading (state) {
    //   state,loadingShow = true
    // }
  },
  actions: {
  },
})
