import router from '@/router';
import {
  getToken,
  setToken,
  removeToken,
  setAccType
} from '@/utils/auth'
const user = {
  state: {
    user: ""
  },
  mutations: {
    USER_INFO(state, info) {
      state.user = info
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    DEL_USER(state, info) {
      localStorage.removeItem('VUEX_STORE');
      state.user = info
    }
  },
  actions: {
    saveUserInfo({ commit }, data) {
      commit('USER_INFO', data)
    },
    delUserInfo({ commit }) {
      commit('DEL_USER', '')
    },
    // 登出
    LogOut({
      commit,
      state
    }) {
      
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '')
        removeToken()

        router.replace('/login');
        // LogOut(state.token).then((response) => {
        //   // console.log('退出--', response)
        //   const data = response.data
        //   if (errorStatus(data)) {
        //     commit('SET_TOKEN', '')
        //     removeToken()
        //   }
        //   resolve()
        // }).catch(error => {
        //   reject(error)
        // })
      })
    },
  }
};
export default user