<template>
  <div class="Main">
    <el-row class="dibu">
      <el-col :span="19">
        <el-row>
          <el-col :span="14">
            <div class="lianxi">
              <div class="lianxitop">联系方式</div>
              <div class="tubiao">
                <div class="el-icon-phone tubiaoone marignTop12" style="margin: 0">
                  022-29503270
                </div>

                <div class="el-icon-message tubiaoone marignTop12">
                  support@smartgenomics.cn
                </div>
                <div class="el-icon-basketball tubiaotwo marignTop12">
                  <a class="yangshi" href="https://www.smartgenomics.net" target="_black"
                    >www.smartgenomics.net</a
                  >
                </div>
                <div class="el-icon-location-outline tubiaoone marignTop12">
                  天津市武清区财富兴园国际企业公园43栋1门
                </div>
                <div class="el-icon-location-outline tubiaothree marignTop12">
                  <span class="marignTop20"
                    >山东省青岛市高新区新业路31号远创国际蓝湾创意园一期B8座</span
                  >
                </div>
                <div class="el-icon-location-outline tubiaothree marignTop12">
                  <span class="marignTop20"
                    >海南省三亚市崖州区崖州湾科技城雅布伦产业园3号楼</span
                  >
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="chanpin">
              <div class="lianxitop">产品服务</div>
              <div class="cpfw marignTop12" style="margin: 0">动植物研究</div>
              <div class="cpfw marignTop12">人类研究</div>
              <div class="cpfw marignTop12">微生物研究</div>
              <div class="cpfw marignTop12">蛋白代谢研究</div>
              <div class="cpfw marignTop12">单细胞空转研究</div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="pingtai">
              <div class="lianxitop">平台服务</div>
              <div class="ptfw marignTop12" style="margin: 0">极智云数据分析平台</div>
              <div class="ptfw marignTop12">智慧育种设计平台</div>
              <div class="ptfw marignTop12">数据库</div>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="5" style="text-align: right">
        <img src="@/assets/gongzhonghaoerweima.jpg" class="dibusrc" alt=""
      /></el-col>
    </el-row>

    <div class="zuibottomBox">
      <div class="zuibottom">
        Copyright ©2020-{{ year }}.ALL Rights Reserved 极智基因版权所有
        津ICP备17019238号-1
      </div>
    </div>

    <!--     <el-button type="text" class="Feedback" @click="jump">意见反馈</el-button> -->
  </div>
</template>

<script>
export default {
  name: "home",
  props: {
    //传值
  },
  data() {
    return {
      year: "",
    };
  },
  components: {
    //引入模块
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    console.log("Home");
    var date = new Date();
    var year = date.getFullYear();
    this.year = year;
  },
  methods: {
    // 组件的方法
    jump() {
      this.$router.push({ path: "/Feedback" });
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style scoped>
.Main {
  /* height: 70px; */
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  /* line-height: 70px; */
  text-align: center;
  position: relative;
  background-color: #212223;
  height: fit-content;
}
.Feedback {
  position: absolute;

  right: 13%;
  top: 16px;
}
.dibu {
  width: 1200px;
  height: 320px;
  background-color: #212223;
  margin: auto;
  padding: 35px 0;
}
.dibuzong {
  width: 1200px;
  margin: 0 auto;
  display: flex;
}
.dibuleft {
  display: flex;
  width: 940px;
}
.lianxi {
  /* height: 158px;
  line-height: 158px; */
  /* margin-top: 56px; */
}
.chanpin {
  position: relative;
  width: fit-content;
  /* width: 112px;
  height: 40px; */
  /* margin-top: 56px; */
}
.chanpin::after {
  padding-top: 35px;
  content: " ";
  width: 1px;
  height: 207px;
  background: #343434;
  position: absolute;
  left: -34px;
  top: 0;
}
.chanpin::before {
  padding-top: 35px;
  content: " ";
  width: 1px;
  height: 207px;
  background: #343434;
  position: absolute;
  right: -42px;
  top: 0;
}
.lianxitop {
  margin-bottom: 18px;
  /* height: 40px; */
  font-size: 28px;
  color: #63666e;
  text-align: left;
  /* line-height: normal; */
}
.kejitop {
  color: #63666e;
  font-size: 34px;
  /* display: flex; */
  /* width: 136px; */
  /* height: 48px; */
  padding-top: 50px;
  /* align-items: center; */
}
.kejibottom {
  display: flex;
  color: #fff;
  width: 838px;
  height: 40px;
  font-size: 20px;
  margin-top: 8px;
  align-items: center;
}
.lxwm {
  color: #63666e;
  font-size: 34px;
  display: flex;
  align-items: center;
  width: 136px;
  height: 48px;
  padding-top: 33px;
}

.diburight {
  margin-top: 56px;
  margin-left: 72px;
}
.dibusrc {
  width: 209px;
  height: 209px;
  margin-top: 20px;
}
.zuibottomBox {
  height: 54px;
  width: 100%;
  position: relative;
  background-color: #2e2f30;
}
.zuibottom {
  position: absolute;
  left: 640px;
  font-size: 12px;
  color: #515458;

  line-height: 54px;
}
.tubiao {
  width: 510px;
  float: left;
}
.yangshi {
  border-bottom: 1px solid #fff;
  color: #ebebeb;
}
.yangshi:hover {
  color: #ffffff;
}
.tubiaotwo {
  text-align: left;
  display: block;
  color: #ffffff;
  font-size: 14px;
  /* margin-top: 13px; */
  font-family: PingFangSC-Regular, PingFang SC;
}
.tubiaothree {
  text-align: left;
  display: block;
  color: #ffffff;
  font-size: 14px;
  /* margin-top: 13px; */
  font-family: PingFangSC-Regular, PingFang SC;
}
.marignTop12 {
  margin-top: 12px;
  line-height: 22px;
  color: #ebebeb !important;
}
.marignTop20 {
  line-height: 20px; /* no */
}
.tubiaoone {
  text-align: left;
  display: block;
  color: #ffffff;
  font-size: 14px;
  /* margin-top: 13px; */
  font-family: PingFangSC-Regular, PingFang SC;
}
.pingtai {
  text-align: left;
  /* margin-top: 56px; */
}
.biankuang {
  margin-top: 60px;
  margin-left: 30px;
  margin-right: 30px;
  height: 207px;
  width: 1px;
  background: #343434;
}
.el-icon-location-outline:before {
  margin-right: 16px;
}
.el-icon-phone::before {
  margin-right: 16px;
}
.el-icon-message::before {
  margin-right: 16px;
}
.el-icon-basketball::before {
  margin-right: 16px;
}
.cpfw {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  /* margin-top: 12px; */
  text-align: left;
}
.ptfw {
  /* margin-top: 12px; */
  width: 144px;
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
}
</style>
