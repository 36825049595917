<template>
  <div class="imgBox">
    <img src="@/assets/logo copy.png" />
    <div class="menuBox">
      <menu-tab :top="top"></menu-tab>
    </div>
    <!-- <el-button type="text"><i class="el-icon-search"></i></el-button> -->
    <el-button
      icon="el-icon-search"
      circle
      class="search"
      :style="top <= 100 ? '' : 'color:#fff'"
    ></el-button>
    <el-dropdown class="login" v-if="this.$Storage.getItem('adm_name')">
 <el-button
      type="text"
      class="login"
      :style="top <= 100 ? '' : 'color:#fff'"
      
      ><span class="loginname">{{this.$Storage.getItem('adm_name')}}</span><el-avatar :src="avatarImag"></el-avatar></el-button
    >
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item @click.native="loginBtn" v-if="$Storage.getItem('adm_email').indexOf('smartbreeding')==-1">个人中心</el-dropdown-item>
    <el-dropdown-item @click.native="exit">退出</el-dropdown-item>
  </el-dropdown-menu>
</el-dropdown>
    <el-button type="text" class="login" v-else @click="loginBtn">登录</el-button>
  </div>
</template>

<script>
import avatarImag from "@/assets/moren.png"
import MenuTab from "@/components/MenuTab/index2.vue";
import { updatePassword } from "@/api/login";
export default {
  name: "TopNav",
 props: {
    //传值
    top:{
      type:Number,
      default:0,
    }
  },
  data() {
    var validatePass = (rule, value, callback) => {
      var pPattern = /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])\S*$/; //数字、字母、长度不小于6  特殊字符(?=\S*[!@#$%^&*? ])
      if (value === "") {
        callback(new Error("请输入密码"));
      } /* else if (!pPattern.test(value)) {
        callback(new Error("必须包含数字、大小写字母组合并且长度不小于6"));
      } */ else {
        if (this.form.passwd_repeate !== "") {
          this.$refs.form.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      var pPattern = /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])\S*$/; //数字、字母、长度不小于6  特殊字符(?=\S*[!@#$%^&*? ])
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } /* else if (!pPattern.test(value)) {
        callback(new Error("必须包含数字、大小写字母组合并且长度不小于6"));
      } */ else if (value !== this.form.passwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      avatarImag:avatarImag,
      retrieval: "",
      pwdType: "password",
      pwdType1: "password",
      dialogVisible: false,
      dropShow: true,
      retrieval: "",
      form: {
        passwd: "",
        passwd_repeate: "",
      },
      formrules: {
        passwd: [{ validator: validatePass, trigger: ["blur", "change"] }],
        passwd_repeate: [
          { validator: validatePass2, trigger: ["blur", "change"] },
        ],
      },
    };
  },
  components: {
    //引入模块
    MenuTab,
  },
  methods: {
    // 组件的方法
    loginBtn(){
      if(!this.$Storage.getItem('adm_name')){
        this.$router.push({path:"/login"})
       
      }else{
        console.log('个人中心')
        this.$router.push({path:"/personal"})
      }
    },
    /* 退出 */
    exit() {
      this.$Storage.removeItem("adm_id");
      this.$Storage.removeItem("adm_name");
      this.$Storage.removeItem("adm_email");
      this.$Storage.removeItem("Admin-Token");
      this.$store.dispatch("delUserInfo");
      this.$router.push({ path: "/login" });
    },
    dropDownChange(val) {
      console.log(val);
      this.dropShow = !val;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    showPwd(event) {
      this.pwdType === "password"
        ? (this.pwdType = "")
        : (this.pwdType = "password");
    },
    showPwd1(event) {
      this.pwdType1 === "password"
        ? (this.pwdType1 = "")
        : (this.pwdType1 = "password");
    },
    xiugai() {
      this.dialogVisible = true;
    },
    dialogPrimary() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.$store.state.user.user);
          this.form.email = this.$Storage.getItem("adm_email");
          updatePassword(this.form).then((response) => {
            if (response.data.code == 1) {
              // this.$message.success(response.data.msg);
              this.$message({
                showClose: false,
                message: response.data.msg,
                type: "success",
                offset: "100",
              });
              this.$Storage.removeItem("adm_id");
              this.$Storage.removeItem("adm_name");
              this.$Storage.removeItem("adm_email");
              /* localStorage.removeItem('adm_id') */
              this.$store.dispatch("delUserInfo");
              this.$router.push({ path: "/login" });
            } else {
              // this.$message.error(response.data.msg);
              this.$message({
                showClose: false,
                message: response.data.msg,
                type: "error",
                offset: "100",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    console.log("Home");
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style scoped>
.el-dropdown-menu__item:hover{
  outline: 0 !important;
    color: #08308F !important;
    background: none !important;
}
.headertwo {
  color: #fff;
  box-shadow: none;
  /*  transition: 0.4s background-color, color 0.4s; */
  background: #2c6ffe;
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 70px ;
  line-height: 70px;
  padding: 0 240px;
}
.headerback {
  background: #2c6ffe ;
}
.headerBox {
  /* height: 830px ; */
  position: fixed;
  background: rgba(0, 0, 0, 0.05);
  z-index: 10000;
  width: 100%;
  height: 70px ;
  line-height: 70px;
  padding: 0 240px;
}
.imgBox {
  width: 1200px !important;
  position: relative;
  margin: 0 auto;
  height: 100%;
}
.imgBox img {
  width: auto;
  height: 38px;
  position: absolute;
  top: 50%;
  margin-top: -19px;

}
.search {
  width: 40px;
  height: 40px;
  color: #ffffff;
  opacity: 1;
  background: transparent;
  border: 0;
  position: absolute;
  right: 100px;
  top: 15px;
  padding: 0;
  /*  margin-top: -20px; */
}
.login {

  height: 70px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -35px;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000;
  line-height: 26px;
}
.loginname{
  position: absolute;
    right: 50px;
    top: 20px;
}
.bannerBox {
  position: relative;
}
.bannerBox .bannerMenu {
  position: absolute;
  top: 98px;
  left: 240px;
  z-index: 1000;
}
.mainBox {
  padding: 40px 240px;
}
.TitleBox {
  height: 43px;
}
.tagBox {
  float: left;
  margin: 7px 0 6px 32px;
}
.Title {
  float: left;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}
.more {
  float: right;
  margin-right: 27px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}
.tagMarginRight {
  margin-right: 24px;
  cursor: pointer;
}
.tag-active {
  background: #2589ff;
  color: #fff;
}
.tag {
  background: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.boxView {
  margin: 32px 0;
}
.cartag {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
  line-height: 22px;
}
.carNum {
  float: right;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
  line-height: 22px;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
}
.introduce {
  background: #f7f8fb;
  padding: 10px 16px;
  border-radius: 5px;
}
.button {
  padding: 0;
  float: right;
}
.carTitle {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;
  width: 100%;
  height: 48px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.carTitleIntroduce {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 22px;
  width: 100%;
  height: 44px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.bannerImage {
  width: 100%;
  height: 100%;
}
.image {
  width: 100%;
  height: 231px;
  display: block;
}
.menuBox {
  width: 100%;
  /* height: 70px; */
  position: absolute;
  left: 10%;
  margin-right: -50%;
  top: 0.038125rem;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}


.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
<style lang="less" scoped>
/deep/.el-carousel__indicators--horizontal {
  position: absolute;
  left: auto;
  right: 10px;
  bottom: 10px;
  text-align: right;

  .el-carousel__indicator--horizontal button {
    width: 8px;
    height: 8px;
    background: #ffffff;
    border-radius: 50%;
    opacity: 0.5;
  }

  .el-carousel__indicator--horizontal.is-active button {
    width: 24px;
    height: 8px;
    background: #ffffff;
    opacity: 0.5;
    border-radius: 10px;
  }
}
</style>
<style>
.el-menu-item {
  font-size: 16px ;
  
  font-weight: 400 ;
  color: #ffffff ;
  transition: none ;
  padding: 0 32px ;
}
.headertwo{
  border-bottom:1px solid #C6C6C6 !important;
  
}
/* 消除小三角 */
.el-popper[x-placement^=bottom] .popper__arrow{
  border: none;
}
.el-popper[x-placement^=bottom] .popper__arrow::after{
  border: none;

}
.el-dropdown-menu{
  top:55px ;
  z-index: 999999 !important;

}

/* .el-submenu__title{
   font-size: 16px ;
  
  font-weight: 400 ;
  color: #ffffff ;
  line-height: 32px ;
  transition: none ;
  padding: 0 32px ;
}
.el-submenu__title i {
  color: currentColor ;
} */

</style>