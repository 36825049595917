import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {createRouter,createWebHistory} from 'vue-router'
Vue.use(VueRouter)

const routes = [
  //首次加载时先走登录页
  {
    path: '/login',
    name: 'login',
    component: () => {
      return import("@/views/login.vue")
    }
  }, 
  {
    path: '/welcome',
    name: 'welcome',
    component: () => {
      return import("@/views/welcome.vue")
    }
  },

  //路由重定向
  {
    path: '/',
    redirect: "/welcome"
  },
  /* 总览 */
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
  },
  /* 项目中心 */
  {
    path: '/ProjectCenter',//项目中心
    name: 'ProjectCenter',
    component: { render: (e) => e("router-view") },
    children: [

      {
        path: 'MyProject',//我的项目
        name: 'MyProject',
        component: () => import('../views/MyProject.vue'),
        meta: { list: [{ name: "项目中心" }, { name: "我的项目" }] ,keepAlive:true}
      },
      {
        path: 'AfterSales',//售后
        name: 'AfterSales',
        component: () => import('../views/AfterSales/index.vue'),
        meta: { activeMenu: '/ProjectCenter/MyProject' }
      },
     
      {
        path: 'MyMission',//我的任务
        name: 'MyMission',
        component: () => import('../views/MyMission.vue'),
        meta: { list: [{ name: "项目中心" }, { name: "我的任务" }] ,keepAlive:true}
      },
      {
        path: 'MySample',//我的样品
        name: 'MySample',
        component: () => import('../components/developing.vue'),
      },
      {
        path: 'MyData',//我的数据
        name: 'MyData',
        component: () => import('../components/developing.vue'),
      },
      {
        path: 'MyCloudGroup',//我的云组
        name: 'MyCloudGroup',
        component: () => import('../components/developing.vue'),
      },
      {
        path: 'MyCourse',//我的课程
        name: 'MyCourse',
        component: () => import('../components/developing.vue'),
      },
    ]
  },
  /* 数据库 */
  {
    path: '/database',
    name: 'database',
    component: () => import('../components/database.vue'),
    meta: { list: [{ name: "首页",path:"/welcome" }, { name: "数据库"}] }
  },
  /* 科研云服务 */
  {
    path: '/cloudServices',
    name: 'cloudServices',
    component: { render: (e) => e("router-view") },
    children: [

      {
        path: 'cloudProcess',
        name: 'cloudProcess',
        component: () => import('../views/cloudProcess.vue'),

      },
      {
        path: 'cloudTools',
        name: 'cloudTools',
        component: () => import('../views/cloudTools.vue'),
      },
      {
        path: 'cloudDraw',
        name: 'cloudDraw',
        component: () => import('../views/cloudDraw.vue'),
      },
    ]
  },
  /* 极智云课程 */
  {
    path: '/Product',
    name: 'Product',
    component: { render: (e) => e("router-view") },
    children: [

      {
        path: 'courses',
        name: 'courses',
        component: () => import('../components/Product/courses.vue'),

      },
      {
        path: 'program',
        name: 'program',
        component: () => import('../components/Product/program.vue'),
      },
      {
        path: 'cooperation',
        name: 'cooperation',
        component: () => import('../components/Product/cooperation.vue'),
      },
      {
        path: 'training',
        name: 'training',
        component: () => import('../components/Product/training.vue'),
        meta: { list: [{ name: "极智云课程"}, { name: "培训班"}] }
      },
      {
        path: 'articleView',
        name: 'articleView',
        component: () => import('../components/Product/articleView.vue'),
        meta: { list: [{ name: "极智云课程"}, { name: "文献"}] }
      },
    ]
  },
  /* 极智云介绍 */
  {
    path: '/introduce',
    name: 'introduce',
    component: { render: (e) => e("router-view") },
    children: [

      {
        path: 'platform',
        name: 'platform',
        component: () => import('../components/introduce/platform.vue'),

      },
      {
        path: 'basic',
        name: 'basic',
        component: () => import('../components/developing.vue'),
      },
      {
        path: 'method',
        name: 'method',
        component: () => import('../components/introduce/method.vue'),
      },
      {
        path: 'FAQ',
        name: 'FAQ',
        component: () => import('../components/FAQ/index.vue'),
      },
      {
        path: 'video',
        name: 'video',
        component: () => import('../components/videoList/index.vue'),
      },
    ]
  },
  {
    path: '/yuzhong',
    name: 'yuzhong',
    component: () => import('../components/yuzhong/index.vue'),
  },
  {
    path: '/cloudServices/cloudProcess/Subpage',//云流程子页
    name: 'Subpage',
    component: () => import('../views/Subpage.vue'),
    meta: { activeMenu: '/cloudServices/cloudProcess' }
  },
  {
    path: '/cloudServices/cloudTools/Subpage',//云工具子页
    name: 'SubpageTools',
    component: () => import('../views/Subpage.vue'),
    meta: {activeMenu: '/cloudServices/cloudTools' }
  },
  {
    path: '/ProjectCenter/MyMission/lookMission',//我的任务查看页面
    name: 'lookMission',
    component: () => import('../views/lookMission.vue'),
    meta: { keepAlive: false,  activeMenu: '/ProjectCenter/MyMission', list: [{ name: "项目中心" }, { name: "我的任务", path: "/ProjectCenter/MyMission" }, { name: "查看任务" }] }
  },
  {
    path: '/cloudServices/cloudDraw/DrawCavas',//绘图工具子页
    name: 'DrawCavas',
    component: () => import('../components/Draw/DrawCavas.vue'),
    meta: { keepAlive: false, activeMenu: '/cloudServices/cloudDraw', list: [{ name: "科研云服务" }, { name: "绘图工具", path: "/cloudServices/cloudDraw" }, { name: "" }] }
  },
  {
    path: '/videoPage',//视频页面
    name: 'videoPage',
    component: () => import('../views/videoPage.vue'),
    meta: { list: [{ name: "首页",path:"/welcome" }, { name: "视频"}] }
  },
    /* 个人中心 */
    {
      path: '/personal',
      name: 'personal',
      component: () => import('../components/personal/index.vue'),
    },
   
    /* 文章详情页 */
    {
      path: '/article',
      name: 'article',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../components/article/index.vue')
    },
    /* 意见建议 */
    {
      path: '/Feedback',
      name: 'Feedback',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../components/Feedback/index.vue')
    },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/jybdxqy',
    name: 'jybdxqy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AfterSales/zhComponents/jybdspfx/jybdxqyView.vue')
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_SRC, 
  routes
})

export default router
