<template>
  <div>
    <el-upload
      class='uploadDom'
      action
      :ref="refName"
      :limit="1"
      :file-list="inputFile"
      :show-file-list='showFile'
      :auto-upload='false'
      :on-change="(file)=>{return changeFile(file)}"
      :on-exceed="(files, fileList)=>{return handleExceed(files, fileList)}"
      :before-remove="(files, fileList)=>{return handleRmove(files, fileList)}"
    >
      <!-- <el-button slot="trigger" size="small" type="primary">上传<i class="el-icon-upload"></i></el-button> -->
      <el-button slot="trigger" size="small" type="primary" @click="handleBeforeUpload">选取文件</el-button>
      <el-tooltip v-if="tipFlag" placement="bottom" effect="light">
        <div slot="content">{{explain}}</div>
        <i class="el-icon-question self-position"></i>
      </el-tooltip>  
      <span class="progress inline-block mleft200" v-show="progrs"><span>上传进度:<span v-text="percentData"></span>%</span></span>
      <el-button v-show="errBtn" size="small" type="primary" @click="continueUpload">继续上传</el-button>
      <a v-if='downloadUrl' :href="downloadUrl" target="_blank" class='downloadBtn'>下载示例文件</a>
      <div class='tips' v-show="successFlag && fatherFlag">文件{{fileName}}的大小为{{fileSize}}，行数为{{rowNum}}</div>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'UploadComponent',
  props: {
    url: { type: String, default: '' }, // 上传文件路径
    refName: {type: String, default: '' }, 
    regList: { type: Array, default: function () {
      return [
          {reg: /[\u4e00-\u9fa5|\(|\)|\s]/gm, msg: '输入文件名不能含有中文以及小括号、空格', reverse: false},
          {reg: /(\.xls|\.xlsx|\.zip|\.rar)$/, msg: '只能上传excel、zip、rar格式的文件', reverse: true},
        ] 
      }
    },
    file: { type: Array, default: [] },
    // filter: { type: Object, default () { return {} } },
    // arraySpanMethod: {type: Function,default () { return function(){} } },
    showFile: { type: Boolean, default: true }, // 是否显示文件
    downloadUrl: { type: String, default: '' }, // 下载示例文件路径
    tipFlag: { type: Boolean, default: false }, // 是否显示提示信息，针对RNA的？提示
    explain: { type: String, default: '' }, // 鼠标放到？上显示的提示信息,
    fatherFlag: { type: Boolean, default: false },
    extraParams: { type: Object, default: () => null}
  },
  data () {
    return {
      inputFile: this.file,
      progrs: false, // 是否显示进度
      percentData: '', // 详细进度
      errBtn: false, // 是否上传失败
      upflag: '', // 当前上传文件的名字
      postFlag: true,
      successFlag:false,//上传文件成功后是否返回需要显示的提示信息
      fileName:null,//上传成功后返回的文件名
      fileSize:null,//上传成功后返回的文件大小
      rowNum:null,//上传成功后返回的文件行数,
      timeDate:null,//上传文件的时间戳
    }
  },
  methods: {
    // 文件操作
    changeFile(file) {
      if (file) {
        // let nameArr = file.raw.name.split('.');
        // let flag = true
        var regList = this.regList
        for (var i in regList) {
          if(regList[i].reverse){
            if (!(regList[i].reg.test(file.raw.name))) {
              // flag = false
              this.$message({
                showClose: true,
                message: regList[i].msg,
                type: 'error',
                offset:'100'
              });
              this.inputFile = [];
              this.progrs = false;
              this.errBtn=false;
              this.successFlag = false;
              this.percentData = '';
              return
            }
          } else {
            if (regList[i].reg.test(file.raw.name)) {
              // flag = false
              this.$message({
                showClose: true,
                message: regList[i].msg,
                type: 'error',
                offset:'100'
              });
              this.inputFile = [];
              this.progrs = false;
              this.errBtn=false;
              this.successFlag = false;
              this.percentData = '';
              return
            }
          }
        }
        // if (file.size >= 104857600) {
          this.upflag = file.raw.name;
          this.inputFile[0] = file;
          this.progrs = true;
          this.errBtn=false;
          this.successFlag = false;
          this.upload(file.raw, 0);
        // } else {
        //   this.inputFile[0] = file;
        //   this.progrs = false;
        //   this.errBtn=false;
        //   this.uploadFile(file.raw);
        // }
      }else{
        this.progrs = false;
        this.errBtn=false;
        this.successFlag = false;
        this.percentData = '';
      }
    },
    handleBeforeUpload(e) {
      if (this.extraParams && !this.extraParams.task_id) {
        this.$message({
          showClose: true,
          message: '请填写任务名称',
          type: 'error',
                offset:'100'
        });
        e.stopPropagation();
        e.preventDefault();
        return false;
      }
    },
    handleExceed(files, fileList) {
      this.$message({
        showClose: true,
        message: '超出上传限制！',
        type: 'error',
                offset:'100'
      });
    },
    handleRmove(file, fileList) {
      this.inputFile.length = 0;
      this.progrs = false;
      this.errBtn = false;
    //  this.postFlag = false;
      this.successFlag = false;
      this.percentData = '';
      this.$emit('removeFile', file)
    },
    upload(file, skip){
      var _this = this;
      var formData = new FormData(); //初始化一个FormData对象
      var blockSize = 10 * 1024 * 1024; //10M每块的大小
      var nextSize = Math.min((skip + 1) * blockSize, file.size); //读取到结束位置
      var fileData = file.slice(skip * blockSize, nextSize); //截取 部分文件 块
      //文件名添加随机值，同一个文件保持一致
      if(skip == 0){
        _this.timeDate = new Date().getTime();
      }
      // console.log(_this.timeDate + file.name);
      formData.append("file", fileData); //将 部分文件 塞入FormData
      formData.append("fileN", _this.timeDate + file.name + skip); //保存文件名git
      formData.append("index", skip); //保存当前碎片序列
      formData.append("total", Math.ceil(file.size / blockSize)); //保存总共文件序列
      formData.append("rolenum", _this.$store.state.role);
      formData.append("pjcode", _this.$store.state.pjcode);
      formData.append("uid", _this.$route.params.id);
      formData.append("need_fileinfo", 1);//表示需要返回提示信息显示
      // 如果extraParams 存在 --- 质谱需要
      if (this.extraParams) {
        // console.log('看看这里接收的数据', this.extraParams)
        const {toolsid = 0, task_id = '' } = this.extraParams;
        formData.append("toolsid", toolsid);
        formData.append("task_id", task_id);
      }
      // formData.append("flag", 2); // 是否为大文件上传的标志量  1为小文件上传2为大文件上传
      // console.log(formData.get("fileN"));
      if (file.name == _this.upflag) {
          let config = {
            headers: {
            'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
          }
          axios.post(this.url,formData,config).then( data => {
            // console.log(skip + 1, Math.ceil(file.size / blockSize));
            if (skip + 1 <= Math.ceil(file.size / blockSize)) {
                _this.percentData = Number(
                  ((skip + 1) / Math.ceil(file.size / blockSize)) * 100
                ).toFixed(0);
            } else {
                return;
            }
            if (file.size <= nextSize) {
                //如果上传完成，则跳出继续上传
                // _this.$message(data.msg) 
                this.successFlag = true;
                this.fileName = data.data.fileName
                this.rowNum = data.data.rowNum
                this.fileSize = data.data.fileSize
                this.$emit("fileTime",_this.timeDate + file.name);
                this.$emit("completeFlag",_this.successFlag);
                return;
            }
            if (data.code == 1) {
                _this.upload(file, data.index);
            } else {
              if(this.postFlag){
                _this.upload(file, ++skip); //递归调用
              }else{
                this.postFlag = true;
                return;
              }
            }
        }).catch( res => {
            _this.errBtn = true;
        })
      }
    },
    continueUpload() {
      var _this = this;
      this.changeFile(_this.inputFile[0]);
    },
    // uploadFile(file){
    //   var that = this
    //   var formData = new FormData(); //初始化一个FormData对象
    //   formData.append("file", file); //将 部分文件 塞入FormData
    //   formData.append("uid", that.userId);
    //   // formData.append("flag", 1); // 是否为大文件上传的标志量  1为小文件上传2为大文件上传
    //   let config = {
    //     headers: {
    //     'Content-Type': 'multipart/form-data' 
    //     },
    //     withCredentials: true
    //   }
    //   axios.post(this.url,formData,config).then( data => {
    //     that.$message(data.msg)
    //   })
    // },
  }
}
</script>
<style lang="scss">
.downloadBtn{
  display: inline-block;
  margin-left: 30px;
  color: #409EFF;
}
.tips{
  color:#F8AC59;
}
</style>
