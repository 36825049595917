<template>
  <div class="Main">
    <el-menu
      :default-active="navLeftActive"
      class="el-menu-demo TopMenu"
      background-color="#0c8bae"
      active-text-color="#fff"
       active-background-color="#0c6279"
      text-color="#fff"
      :router="true"
       mode="horizontal" 
     
      @select="handleSelect"
    ><!-- rgb(0 0 0 / 0%) -->
      <el-menu-item index="/home">总览</el-menu-item>
      <el-submenu index="/ProjectCenter">
        <template slot="title">项目中心</template>
        <el-menu-item index="/ProjectCenter/MyProject">我的项目</el-menu-item>
        <el-menu-item index="/ProjectCenter/MyMission">我的任务</el-menu-item>
        <el-menu-item index="/ProjectCenter/MySample">我的样品</el-menu-item>
        <el-menu-item index="/ProjectCenter/MyData">我的数据</el-menu-item>
        <el-menu-item index="/ProjectCenter/MyCloudGroup">我的云组</el-menu-item>
        <el-menu-item index="/ProjectCenter/MyCourse">我的课程</el-menu-item>
      </el-submenu>
      <el-submenu index="/cloudServices">
        <template slot="title">科研云服务</template>
        <el-menu-item index="/cloudServices/cloudProcess">云流程</el-menu-item>
        <el-menu-item index="/cloudServices/cloudTools">云工具</el-menu-item>
        <el-menu-item index="/cloudServices/cloudDraw">绘图工具</el-menu-item>
      </el-submenu>
      <el-menu-item index="/database">数据库</el-menu-item>      
      <el-submenu index="/Product">
        <template slot="title">极智云课程</template>
        <el-menu-item index="/Product/courses">产品课程</el-menu-item>
        <el-menu-item index="/Product/program">编程课程</el-menu-item>
        <el-menu-item index="/Product/cooperation">合作项目详解</el-menu-item>
        <el-menu-item index="/Product/training">培训班</el-menu-item>
      </el-submenu>
      <el-submenu index="/introduce">
        <template slot="title">极智云介绍</template>
        <el-menu-item index="/introduce/platform">平台介绍</el-menu-item>
        <el-menu-item index="/introduce/basic">基础功能</el-menu-item>
        <el-menu-item index="/introduce/method">使用方法</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "MenuTab",
  data() {
    return {};
  },
  components: {
    //引入模块
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
    navLeftActive: function () {
      console.log(this.$route);
      const { meta, path } = this.$route;
      console.log(path, 111);
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path; //"/" + path.split("/")[1];
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    console.log("Home");
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style scoped>
.Main>>>.el-menu-item.is-active{
  background-color: #0c6279 !important;
}
.TopMenu{
  border: none;
  text-align: left;
}
.TopMenu >>>.el-submenu__title i{
    color: currentColor;
}
.TopMenu {
  border-bottom: 0px !important;
}
</style>