import request from '@/utils/request'

// 获取第一页需要的全部流程
export function getAllProcessList(data) {
   
    return request({
        url: '/getAllProcessList',
        method: 'post',
        data
    })
}
// 显示Banner图
export function showBannerImg(data) {
   
    return request({
        url: '/showBannerImg',
        method: 'post',
        data
    })
}
// 累加访问量
export function addPvNum(data) {
   
    return request({
        url: '/addPvNum',
        method: 'post',
        data
    })
}
// 视频列表
export function getListAssetList(data) {
   
    return request({
        url: '/getListAssetList',
        method: 'post',
        data
    })
}
// 累加播放的次数
export function addVideoPlayNum(data) {
   
    return request({
        url: '/addVideoPlayNum',
        method: 'post',
        data
    })
}
// 收藏视频
export function collectVideo(data) {
   
    return request({
        url: '/collectVideo',
        method: 'post',
        data
    })
}
// 获取视频的标签
export function getVideoTags(data) {
   
    return request({
        url: '/getVideoTags',
        method: 'post',
        data
    })
}
// 查看收藏的视频
export function getCollectVideoList(data) {
   
    return request({
        url: '/getCollectVideoList',
        method: 'post',
        data
    })
}
// 用户是否收藏视频
export function isCollectVideo(data) {
   
    return request({
        url: '/isCollectVideo',
        method: 'post',
        data
    })
}
// 显示数据库列表

export function showDataBaseImg(data) {
   
    return request({
        url: '/showDataBaseImg',
        method: 'post',
        data
    })
}
// 累加数据库访问量
export function addDbNum(data) {
   
    return request({
        url: '/addDbNum',
        method: 'post',
        data
    })
}
// 获取文章列表
export function getArticleList(data) {
   
    return request({
        url: '/getArticleList',
        method: 'post',
        data
    })
}
// 获取文章的标签
export function getArticleTags(data) {
   
    return request({
        url: '/getArticleTags',
        method: 'post',
        data
    })
}
// 获取文章评论列表
export function getArticleCommentList(data) {
   
    return request({
        url: '/getArticleCommentList',
        method: 'post',
        data
    })
}
// 新增评论
export function addComment(data) {
   
    return request({
        url: '/addComment',
        method: 'post',
        data
    })
}
// 删除评论
export function delComment(data) {
   
    return request({
        url: '/delComment',
        method: 'post',
        data
    })
}
// 获取视频评论列表
export function getVideoCommentList(data) {
   
    return request({
        url: '/getVideoCommentList',
        method: 'post',
        data
    })
}
// 新增视频评论
export function addVideoComment(data) {
   
    return request({
        url: '/addVideoComment',
        method: 'post',
        data
    })
}
// 删除视频评论
export function delVideoComment(data) {
   
    return request({
        url: '/delVideoComment',
        method: 'post',
        data
    })
}
// 增加文章查看次数
export function addArticleNum(data) {
   
    return request({
        url: '/addArticleNum',
        method: 'post',
        data
    })
}