var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Main"},[_c('div',{staticClass:"bannerBox"},[_c('el-carousel',{ref:"carousel",staticClass:"bannerBoxTop",attrs:{"height":"2.864583rem","trigger":"click","autoplay":_vm.flag},on:{"change":_vm.carouselChange},nativeOn:{"mouseenter":function($event){return _vm.delHandleMouseEnter.apply(null, arguments)},"mouseleave":function($event){return _vm.delHandleMouseEnter1.apply(null, arguments)}}},_vm._l((_vm.bannerList),function(item){return _c('el-carousel-item',{key:item.id},[(item.path != '#')?_c('a',{attrs:{"href":item.path,"target":"_blank"}},[_c('img',{staticClass:"bannerImage",attrs:{"src":item.img_name}})]):_c('img',{staticClass:"bannerImage",attrs:{"src":item.img_name}})])}),1)],1),_vm._l((_vm.contViewList),function(item,index){return [(item.type == '左右布局')?_c('div',{staticClass:"content1",style:({ '--backgroundColor': '' }),attrs:{"id":index}},[_vm._l((item.img),function(itm,ind){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.location != '左侧对齐'),expression:"item.location != '左侧对齐'"}],key:ind,style:({ float: 'left', width: '50%', 'margin-right': 165 / 192 + 'rem' })},[_vm._l((itm.fileList),function(it){return [_c('img',{class:{
              imgleft: _vm.intersectionObserverList.indexOf(index) != -1,
              imgBoxcon: true,
            },attrs:{"src":_vm.imgurl + it.name}})]})],2)}),_c('div',{staticClass:"jizhiyun",style:({ float: 'left', width: 'fit-content' })},[_c('div',{staticClass:"Title",style:({ '--Color': item.title_color })},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"jieshao"},[_vm._v(_vm._s(item.jieshao))])]),_vm._l((item.img),function(itm,ind){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.location == '左侧对齐'),expression:"item.location == '左侧对齐'"}],key:ind,style:({ float: 'left', width: '50%', 'margin-left': 165 / 192 + 'rem' })},[_vm._l((itm.fileList),function(it){return [_c('img',{class:{
              imgright: _vm.intersectionObserverList.indexOf(index) != -1,
              imgBoxcon: true,
            },attrs:{"src":_vm.imgurl + it.name}})]})],2)})],2):_vm._e(),(item.type == '全布局')?_c('div',{staticClass:"content2",style:({
        'background-image': 'url(' + _vm.imgurl + item.background_url[0].name + ')',
      }),attrs:{"id":index}},[_c('el-row',{staticClass:"jbcol",style:({ width: 1200 / 192 + 'rem', margin: 'auto' }),attrs:{"gutter":33}},_vm._l((item.img),function(itm,ind){return _c('el-col',{key:ind,class:{
            imgdown: _vm.intersectionObserverList.indexOf(index) != -1,
            imgdown1: true,
          },style:({
            paddingLeft: ind == 0 || ind == 3 ? 0 : '16.5px',
            paddingRight: (ind + 1) % 3 ? '16.5px' : 0,
          }),attrs:{"span":8}},[_vm._l((itm.fileList),function(it){return [_c('div',{staticClass:"djht",on:{"click":function($event){return _vm.jump1(itm)}}},[_c('div',[_c('div',{staticClass:"srcimg"},[_c('img',{staticClass:"djhtimg",attrs:{"src":_vm.imgurl + it.name}})]),_c('div',[_vm._v(_vm._s(itm.imgTitle))])])])]})],2)}),1)],1):_vm._e()]}),_c('footer-menu')],2)}
var staticRenderFns = []

export { render, staticRenderFns }