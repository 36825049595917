import request from '@/utils/request'
import requestCircos from '@/utils/requestCircos'
// 获取项目列表
export function getProcessList(data) {
   
    return request({
        url: '/getProcessList',
        method: 'post',
        data
    })
}
// 获取项目列表
export function getProcessOneInfo(data) {
   
    return request({
        url: '/getProcessOneInfo',
        method: 'post',
        data
    })
}
// 获取项目列表
export function getProjectFilePath(data) {
   
    return request({
        url: '/getProjectFilePath',
        method: 'post',
        data
    })
}
// 获取平台介绍、使用方法模块中的信息
export function getIntroduceInfo(data) {
   
    return request({
        url: '/getIntroduceInfo',
        method: 'post',
        data
    })
}
// 获取平台介绍、使用方法模块中的信息
export function getVersionInfoList(data) {
   
    return request({
        url: '/getVersionInfoList',
        method: 'post',
        data
    })
}
// 获取平台介绍、使用方法模块中的信息
export function getProcessTypeList(data) {
   
    return request({
        url: '/getProcessTypeList',
        method: 'post',
        data
    })
}

// 获取示例文件
export function getExampleFileInfo(data) {
   
    return request({
        url: '/getExampleFileInfo',
        method: 'post',
        data
    })
}
// 获取示例文件
export function getProjectJiyinzuBanben(data) {
   
    return request({
        url: '/getProjectJiyinzuBanben',
        method: 'post',
        data
    })
}
// 获取首页饼图的显示数据
export function getPieChartData(data) {
   
    return request({
        url: '/getPieChartData',
        method: 'post',
        data
    })
}
// 运行绘图工具
export function runDrowToolsTask(data) {
   
    return requestCircos({
        url: '/runDrowToolsTask',
        method: 'post',
        data
    })
}
// 运行绘图工具上传
export function runDrowToolsTaskUpload(data) {
   
    return requestCircos({
        url: '/runDrowToolsTask',
        headers: {
            "Content-Type": "multipart/form-data",
          },
        method: 'post',
        data
    })
}
// 下载绘图工具生成的图片
export function downloadDrawToolsPic(data) {
   
    return request({
        url: '/downloadDrawToolsPic',
        method: 'get',
        params:data
    })
}
// 下载示例文件
export function downloadExampleFile(data) {
   
    return request({
        url: '/downloadExampleFile',
        method: 'post',
        data
    })
}
// yu
export function getProjectSampleName(data) {
   
    return request({
        url: '/getProjectSampleName',
        method: 'post',
        data
    })
}
//首页
export function getGongsiList(data) {
   
    return request({
        url: '/getGongsiList',
        method: 'post',
        data
    })
}
//真核有参售后任务
export function getZhenheYoucanTask(data) {
   
    return request({
        url: '/getZhenheYoucanTask',
        method: 'post',
        data
    })
}
//云流程变异检测
export function getCankaoJiyinzu(data) {
   
    return request({
        url: '/getCankaoJiyinzu',
        method: 'post',
        data
    })
}
//真核转录售后获取样本信息
export function shouhouGetSampleGroup(data) {
   
    return request({
        url: '/shouhouGetSampleGroup',
        method: 'post',
        data
    })
}