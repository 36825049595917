<template>
  <div>
<!--     <mu-circular-progress :size="40" v-if="loading"/> -->
    <div v-html="html"></div>
  </div>
</template>
<style>

</style>
<script>
import {getHtml} from "@/api/htmlget"
  export default{
    // 使用时请使用 :url.sync=""传值
    props: {
      url: {
        required: true
      }
    },
    data () {
      return {
        loading: false,
        html: ''
      }
    },
    watch: {
      url (value) {
        this.load(value)
      }
    },
    mounted () {
      this.load(this.url)
    },
    methods: {
      load (url) {
        if (url && url.length > 0) {
          // 加载中
          this.loading = true
          let param = {
            accept: 'text/html, text/plain'
          }
          getHtml(url, param).then((response) => {
            this.loading = false
            // 处理HTML显示
            
            var index=url.lastIndexOf("/")
            var newurl=url.substring(0,index)
            response.data.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, newurl)
            // console.log(newurl)
            this.html = response.data
          }).catch(() => {
            this.loading = false
            this.html = '加载失败'
          })
        }
      }
    }
  }
</script>
