import request from '@/utils/request'
import requestqd from '@/utils/requestqd'
// 获取项目列表
export function bigFileUpload(data,config,qd=null) {
    console.log(qd)
   if(qd){
    return requestqd({
        url: "/bigFileUpload",
        headers: config.headers,
        method: 'post',
        data
    })
   }else{
    return request({
        url: "/bigFileUpload",
        headers: config.headers,
        method: 'post',
        data
    })
   }
  
}