<template>
  <!-- 分类菜单 -->
  <div class="menu-content" :style="backGround">
    <div
      :class="[
        'menu-item',
        menu_item_index1 == index ? 'menu-item-active' : '',
      ]"
      v-for="(item, index) in menu_list"
      :key="index"
      @mouseover="showSubMenu(index)"
      @mouseout="hideSubMenu(index)"
    >
      <!--   -->
      <el-button type="text" class="textBtn">
        <span>{{ item.title }}</span>
        <!-- <i class="el-icon-caret-right el-icon--right fa"></i> -->
        <!--  <i class="fa fa-angle-right"></i> -->
      </el-button>
    </div>
    <!-- 二级菜单 -->
    <div
      :class="['sub-menu']"
      @mouseover="showSubMenu(menu_item_index)"
      @mouseout="hideSubMenu(menu_item_index)"
    >
      <div v-for="(item, index) in menu_list" :key="index">
        <div class="inner-box" v-if="menu_item_index == index">
          <div class="sub-inner-box">
            <!-- <div class="title">{{ item.title }}</div> -->
            <div class="sub-row" v-for="(itm, inx) in item.list" :key="inx">
                <el-row>
                  <el-col :span="24"
                    ><div class="boxTitle">
                      {{ itm.menu_title }}
                    </div></el-col
                  >
                </el-row>
                <el-row :gutter="20">
                  <el-col
                    :span="8"
                    v-for="(it, ix) in itm.menus"
                    :key="ix"
                    class="box"
                    @click.native="jump(it)"
                  >
                    <div
                      class="grid-content"
                      :title="it.process_name"
                      v-if="it.process_name"
                    >
                      {{ it.process_name }}
                    </div>
                    <div
                      class="grid-content"
                      :title="it.name"
                      v-else-if="it.name"
                    >
                      {{ it.name }}
                    </div>
                    <div
                      class="grid-content"
                      :title="it.title"
                      v-else-if="it.title"
                    >
                      {{ it.title}}
                    </div>
                  </el-col>
                </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "menuContent",
  props: {
    //传值
    menu_list: {
      type: Array,
      default: [],
    },
    backGround: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      menu_item_index: -1,
      menu_item_index1: -1,
    };
  },
  components: {
    //引入模块
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    console.log("menuContent");
    this.hideSubMenu(-1);
  },
  methods: {
    // 组件的方法
    /* 显示对应二级菜单 */
    showSubMenu(index) {
      if (index != -1) {
        this.menu_item_index = index;
        this.menu_item_index1 = index;
      }
      document.querySelector(".sub-menu").classList.remove("hide");
    },
    /* 隐藏二级菜单 */
    hideSubMenu(index) {
      console.log(index);
      if (index != -1) {
        this.menu_item_index1 = -1;
      }
      document.querySelector(".sub-menu").classList.add("hide");
    },
    /* 给父组件传值事件 */
    jump(info) {
      this.$emit("jump", info);
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style scoped>
/* 左侧导航 */
a {
  text-decoration: none;
}

a:link,
a:visited {
  color: #5e5e5e;
}
.textBtn {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: currentColor;
  line-height: 22px;
  padding: 0;
}
.container {
  width: 95vw;
  max-width: 1200px;
  height: 460px;
  /* border: 1px solid red; */
  position: relative;
  overflow: hidden;
  margin-left: -450px;
  left: 50%;
}
/* 左侧分类菜单 */
.menu-item-active {
  /* background: #7c7c7c; */
  background: rgba(255, 255, 255, 0.1);
}
.menu-item-hide {
  /* background: #7c7c7c; */
  background: transparent;
}
.menu-content {
  width: 223px;
  /* height: 550px; */
  height: 370px;
  border-radius: 5px;

  /* background-color: rgba(7, 17, 27, 0.25); */
  /* background-color: rgba(15, 41, 80, 0.25); */
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* linear-gradient(to bottom right, #77b0ed, #75bbe1,#75bbe1,#77b0ed); */
  /* position: absolute; */
  /* bottom: 102%;
  left: 16%;
  z-index: 2; */
  /* padding-top: 6px; */
}
.menu-item {
  height: 35px;
  line-height: 35px;
  padding: 0 24px;
  margin: 16px 0 16px 20px;
  cursor: pointer;
}
.menu-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  height: 64px;
  font-size: 16px;
}
.menu-item:last-child a {
  border-bottom: none;
}
.menu-item a span {
  flex: 1;
}
.menu-item .textBtn .fa {
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  position: absolute;
  right: 0;
}
/* 二级分类菜单 */
.sub-menu {
  /* display: none; */
  border: 1px solid #d9dde1;
  background-color: #fff;
  width: calc(100vw - 244px);
  max-width: 1200px;
  /* height: 458px; */
  border-radius: 0 5px 5px 0;
  height: 370px;
  overflow: auto;
  position: absolute;
  left: 211px;
  top: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.menu {
  color: #000;
  margin-left: 10px;
  width: 160px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  min-width: 160px;
}
.menu span:hover {
  width: auto;
}
.hide {
  display: none;
}
.inner-box {
  width: 100%;
  height: 100%;
  /* background: red; */
  overflow: auto;
}
.sub-inner-box {
  width: 100%;
  margin-left: 32px;
  margin-right: 12px;
  width: calc(100% - 40px);
  position: absolute;
}
.sub-inner-box .title {
  /* color: #f01414; */
  font-family: YouYuan;
  width: 94%;
  height: 34px;
  /* border-radius: 5px; */
  border-radius: 0 10px;
  border-bottom: 2px solid #75bbe1e0;
  /* background-color: #75bbe1e0; */
  line-height: 34px;
  padding-left: 14px;
  color: #000000cf;
  font-size: 16px;
  /* line-height: 16px; */
  font-weight: bold;
  margin-top: 28px;
  margin-bottom: 30px;
}
.sub-inner-box .sub-row {
 /*  margin-bottom: 25px; */
  line-height: 25px;
  /* display: flex; */
}
.sub-inner-box .sub-row .bold {
  font-weight: 700;
  margin-right: 15px;
}
.sub-inner-box .sub-row .btnBox {
  /*  width: 70%; */
}
.sub-inner-box .sub-row a {
  margin-left: 15px;
}
.item {
  display: inline-block;
}
.item >>> .el-button--text:hover {
  width: auto !important;
}
.boxTitle {
  margin: 16px 0 0 0;
  color: #000;
}
.box:hover{
  color: #fff;
  background: #0081c6;
}
.box {
  width: 124px;
  height: 30px;
  margin: 16px 20px 4px 0px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  background: #f7fafa;
  text-align: center;
  cursor: pointer;
}
.box .grid-content {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.childerTextBtn {
  margin: 16px 0 0 0;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000;
  line-height: 22px;
  padding: 0;
}
/* 没有二级菜单title */
/* .menuNoTitle:first-child {
  margin-left: 0;
} */
</style>
<style scoped>
/* 动画 */
@keyframes leftCanvas {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(200px);
    opacity: 0;
  }
}

/* .v-enter {
  transform: translateX(-100px);
} */

@keyframes rightCanvas {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.showMenu {
  animation: rightCanvas 2s;
}
.hideMenu {
  animation: leftCanvas 2s;
}
</style>
<style scoped>
.childrenJT{
  display: flex;
  align-items: center;
}
.childrenboxTitle::after {
    content: ">";
}
.childrenboxTitle{
  color: #000;
  font-size: 14px;
}
.childrenBox{
  width: 0.645833rem;
  font-size: 14px;
    /* height: 0.291667rem; */
    margin: 0 20px;
    /* line-height: 0.291667rem; */
    font-size: 0.072917rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    /* border-radius: 0.026042rem; */
    background: #f7fafa;
    text-align: center;
    cursor: pointer;
}
</style>